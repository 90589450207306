/**
 *友盟的常量
 * */
export var UM_SET_META_INFO = 'aplus.setMetaInfo';
export var UM_MATE_NAME_APP_KEY = 'appKey';
export var UM_MATE_NAME_GLOBAL_PROPERTY = 'globalproperty';
export var UM_MATE_NAME_USER_ID = '_user_id';
export var UM_MATE_NAME_USER_NICK = '_user_nick';
export var UM_MATE_NAME_HOLD = '_hold';
export var UM_MATE_NAME_WAITING = 'aplus-waiting';
export var UM_MATE_NAME_OPEN_ID = 'openid';
export var UM_MATE_NAME_UUID = 'uuid';
export var UM_MATE_NAME_ID_TYPE = 'aplus-idtype'; //

export var UM_SEND_PV = 'aplus.sendPV';
export var UM_SEND_RECORD = 'aplus.record'; // 测试事件

export var UM_RECORD_TEST = 'test'; // 登录事件

export var UM_RECORD_LOGIN = 'login'; // 订单业务 orderBusiness

export var UM_RECORD_ORDER_BUSINESS = 'orderBusiness'; // 工单业务 orderBusiness

export var UM_RECORD_WORK_ORDER_BUSINESS = 'workOrderBusiness'; // 页面按钮点击 pageButtonClick

export var UM_RECORD_PAGE_BUTTON_CLICK = 'pageButtonClick'; // 页面存活时间 pageLiveTime

export var UM_RECORD_PAGE_LIVE_TIME = 'pageLiveTime';
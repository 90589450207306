var state = {
  isShowRadio: true
};
var mutations = {
  IS_SHOW_RADIO: function IS_SHOW_RADIO(state, val) {
    state.isShowRadio = val;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};
export function getToken() {
  return localStorage.getItem('token');
}
export function setToken(token) {
  localStorage.setItem('token', token);
}
export function removeToken() {
  localStorage.removeItem('token');
}
export function getReToken() {
  return localStorage.getItem('refresh_token');
}
export function setReToken(token) {
  localStorage.setItem('refresh_token', token);
}
export function removeReToken() {
  localStorage.removeItem('refresh_token');
}
export function getLoginInfo() {
  return localStorage.getItem('logininfo') ? JSON.parse(localStorage.getItem('logininfo')) : null;
}
export function setLoginInfo(logininfo) {
  localStorage.setItem('logininfo', JSON.stringify(logininfo));
}
export function removeLoginInfo() {
  localStorage.removeItem('logininfo');
}
export function getLoginRole() {
  var roles = localStorage.getItem('logininfo') ? JSON.parse(localStorage.getItem('logininfo')).role : [];
  if (roles) return roles.map(function (item) {
    return item;
  });
}
export function getLoginMenus() {
  var menus = localStorage.getItem('logininfo') ? JSON.parse(localStorage.getItem('logininfo')).menus : [];
  if (menus) return menus.map(function (item) {
    return item;
  });
}
export var setStorage = function setStorage(item, data) {
  localStorage.setItem(item, data);
};
export var getStorage = function getStorage(item) {
  return localStorage.getItem(item);
};
export var setTime = function setTime(time) {
  localStorage.setItem('outTime', time);
};
export var getTime = function getTime() {
  return localStorage.getItem('outTime');
};
export var removeTime = function removeTime() {
  localStorage.removeItem('outTime');
};
export var getApiTime = function getApiTime() {
  return isNaN(+localStorage.getItem('apiTime')) ? +localStorage.getItem('apiTime') : new Date().getTime();
};
export var setApiTime = function setApiTime(time) {
  localStorage.setItem('apiTime', time);
};
export var removeApiTime = function removeApiTime() {
  localStorage.removeItem('apiTime');
};
import "core-js/modules/es6.regexp.replace";

/*
用户敏感信息加密
*/

/**
 * @param {string} card
 * @returns {String}
 */
export function certHidden(card) {
  // 身份证加密
  return card.replace(/(\d{6})(\d*)(\d{4})/, '$1********$3');
}
/**
 * @param {string} card
 * @returns {String}
 */

export function otherCertHidden(card) {
  // 其他证件加密
  return card.replace(/([\s\S]*)([\s\S]{4})/, '$1****');
}
/**
 * @param {string} tele
 * @returns {String}
 */

export function telephoneHidden(tele) {
  // 固话加密
  return tele.replace(/(\d*)(\d{4})/, '******$2');
}
/**
 * @param {string} mobile
 * @returns {String}
 */

export function mobileHidden(mobile) {
  // 手机号加密
  return mobile.replace(/(\d{3})(\d*)(\d{4})/, '$1****$3');
}
/**
 * @param {string} name
 * @returns {String}
 */

export function nameHidden(name) {
  // 名字加密
  var nameH = function nameH(num) {
    return new Array(num).join('*');
  };

  if (name.length === 2 || name.length === 3) {
    return name[0] + nameH(name.length);
  } else if (name.length > 3) {
    return name.substr(0, 2) + nameH(name.length - 1);
  } else if (name.length === 1) {
    return '*';
  }
}
/**
 * @param {string} date YYYY-MM-DD
 * @returns {String}
 */

export function dateHidden(name) {
  // 日期加密
  return '****-**-**';
}
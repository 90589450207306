/**
 * @author 籍龙波
 * 来电号码信息
 * */
var state = {
  callInfo: {}
};
var mutations = {
  // 保存来电信息
  saveCallInfo: function saveCallInfo(state, callInfo) {
    state.callInfo = callInfo;
  },
  // 删除来电信息
  deleteCallInfo: function deleteCallInfo(state) {
    state.callInfo = {};
  }
};
var getters = {
  // 获取当前保存的来电信息
  getCallInfo: function getCallInfo(state) {
    return state.callInfo;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters
}; // setter

export var SAVE_CALL_INFO = 'comingCallInfo/saveCallInfo';
export var DELETE_CALL_INFO = 'comingCallInfo/deleteCallInfo'; // getter

export var GET_CALL_INFO = 'comingCallInfo/getCallInfo';
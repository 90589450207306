import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";

/* --------------------------------
功能：
对表单中input输入的电话进行脱敏、是否必填项、是否可编辑、联系方式验证。其中联系方式有以下验证规则（validationType）：
1.座机、手机非必填项，只能输入7至12位的数字（对应参数：notRequiredGeneral）
2.手机非必填项，只能输入11位的数字（对应参数：notRequiredMobile）
2.只能输入7至12位的数字，适用于手机号、座机号（对应参数：general）
3.验证规则是只能输入11位数字，适用于手机号（对应参数：mobilePhone）

使用方式：
在input行内输入v-phone,并在配置相关参数。
      v-phone="{
                that: this,//必传入，that值为vue实例对象
                rulesName:'',//必传入，rulesName为表单的rules字段名称
                name: 'form.callNum',/必传入，/name值为input的v-model的值
                validationType: 'general',//必传入，validationType为验证类型。参数见上文
                isRequired: true,//选填，isRequired为是否为必填项，true为必填项，不传参默认false
                desensitization:true,//选填，desensitization为是否脱敏，true为需要脱敏
                isDisabled:true,//选填，isDisabled为是否可编辑，true为不可编辑
                domStructure:'dropDownBox',//选填，domStructure为DOM元素结构，值为dropDownBox表示可以输入值的下拉框结构，如果不传值，则默认为input框结构。下拉框结构示例见创建新开工单的联系方式。input结构见潜户录入的来电号码
              }"

使用示例：
见PotentialUser.vue文件的“来电号码”使用方式
 ------------------------------ */
export var phone = {
  bind: function bind(el, _ref) {
    var value = _ref.value;
    setTimeout(function () {
      var vm = value.that;
      var modelVal = handleName(value.name, vm);

      if (value.desensitization) {
        var newEl = document.createElement('div');
        newEl.innerHTML = '<div class="el-input"><input type="text" autocomplete="off" placeholder="请输入" class="el-input__inner"></div>';
        el.parentNode.appendChild(newEl);
        newEl.firstChild.firstChild.value = telephoneHidden(modelVal.val);
        el.style.display = 'none';

        if (value.isDisabled) {
          handleDisabled(newEl.firstChild);
        }
      }

      if (value.isDisabled) {
        handleDisabled(el);
      }

      if (value.isRequired) {
        var targetEl;

        if (value.domStructure === 'dropDownBox') {
          targetEl = el.parentNode.parentNode.parentNode.firstChild;
        } else {
          // 适用于input结构，在label标签前面加红星
          targetEl = el.parentNode.parentNode.firstChild;
        }

        targetEl.className += ' redStar';
        loadStyleString(".redStar:before {content: '*';color: #F56C6C;margin-right: 4px;}", targetEl);
      }

      if (value.validationType === 'general') {
        phoneValidatorFn(value, vm, modelVal);
      }

      if (value.validationType === 'mobilePhone') {
        mobilePhoneValidatorFn(value, vm, modelVal);
      }
    }, 10);
  },
  // 当传进来的值更新的时候触发
  componentUpdated: function componentUpdated(el, _ref2) {
    var value = _ref2.value;
    var vm = value.that;
    var modelVal = handleName(value.name, vm);

    if (value.validationType === 'notRequiredMobile') {
      onlyNumValidatorFn(vm, modelVal, 11);
      notRequiredMobilePhoneValidatorFn(value, vm, modelVal);
    }

    if (value.validationType === 'notRequiredGeneral') {
      onlyNumValidatorFn(vm, modelVal, 12);
      notRequiredMobilePhoneValidatorFn(value, vm, modelVal, 7, 12);
    }

    if (value.validationType === 'general') {
      phoneValidatorFn(value, vm, modelVal);
      onlyNumValidatorFn(vm, modelVal, 12);
    }

    if (value.validationType === 'mobilePhone') {
      mobilePhoneValidatorFn(value, vm, modelVal);
      onlyNumValidatorFn(vm, modelVal, 11);
    }
  }
};

var telephoneHidden = function telephoneHidden(tele) {
  return tele.replace(/(\d{1})(\d*)(\d{3})/, '$1******$3');
};

var handleName = function handleName(str, vm) {
  if (~str.indexOf('.')) {
    var labelName = str.substring(str.indexOf('.') + 1);
    var formName = str.substring(0, str.indexOf('.'));
    return {
      val: vm[formName][labelName],
      labelName: labelName,
      formName: formName
    };
  } else {
    return {
      val: vm[str],
      labelName: str
    };
  }
};

var loadStyleString = function loadStyleString(css, ele) {
  var style = document.createElement('style');
  style.type = 'text/css';

  try {
    style.appendChild(document.createTextNode(css));
  } catch (ex) {
    style.styleSheet.cssText = css;
  }

  ele.appendChild(style);
};

var onlyNumValidatorFn = function onlyNumValidatorFn(vm, modelVal, valLimitNum) {
  vm[modelVal.formName][modelVal.labelName] = vm[modelVal.formName][modelVal.labelName].substring(0, valLimitNum).replace(/[^\d]/g, '');
};

var notRequiredMobilePhoneValidatorFn = function notRequiredMobilePhoneValidatorFn(value, vm, val) {
  var min = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 11;
  var max = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 11;
  vm[value.rulesName][val.labelName] = [{
    min: min,
    max: max,
    message: '位数错误',
    trigger: 'change'
  }];
};

var phoneValidatorFn = function phoneValidatorFn(value, vm, val) {
  var phoneValidator = function phoneValidator(rule, value, callback) {
    if (!val.val) {
      return callback(new Error('请输入内容'));
    }

    if (val.val.length < 7) {
      return callback(new Error('请输入7至12位的数字'));
    }

    if (/^\d{7,12}$/.test(val.val)) {
      callback();
    }
  };

  vm[value.rulesName][val.labelName] = [{
    trigger: 'change',
    validator: phoneValidator
  }];
};

var mobilePhoneValidatorFn = function mobilePhoneValidatorFn(value, vm, val) {
  var mobilePhoneValidator = function mobilePhoneValidator(rule, value, callback) {
    if (!val.val) {
      return callback(new Error('请输入内容'));
    }

    if (val.val.length < 11) {
      return callback(new Error('请输入11位的数字'));
    }

    if (!verifyTelNum(val.val)) {
      return callback(new Error('请输入正确的手机号'));
    }

    if (verifyTelNum(val.val)) {
      return callback();
    }
  };

  vm[value.rulesName][val.labelName] = [{
    trigger: 'change',
    validator: mobilePhoneValidator
  }];
};

var verifyTelNum = function verifyTelNum(val) {
  var TelNumreg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;

  if (TelNumreg.test(val)) {
    return true;
  }
};

var handleDisabled = function handleDisabled(el) {
  var inputEl = el.getElementsByTagName('input')[0];
  inputEl.setAttribute('disabled', 'disabled');
  el.className += ' is-disabled';
};
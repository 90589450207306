//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import version from "./../../../../version.js";
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      version: version,
      versionBool: false
    };
  },
  computed: {
    title: function title() {
      return process.env.VUE_APP_HOME_TITLE;
    }
  },
  methods: {
    showVersion: function showVersion() {
      this.versionBool = !this.versionBool;
    }
  }
};
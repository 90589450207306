// import { changeMoney } from '@/utils/index.js';
var state = {
  accountInfo: {},
  deposits: [],
  customers: {},
  broad: [],
  equt: [],
  use: [],
  userinfos: {},
  relUsers: []
};
var mutations = {
  SET_ACCOUNTINFO: function SET_ACCOUNTINFO(state, accountInfo) {
    state.accountInfo = accountInfo;
  },
  RESET_ACCOUNTINFO: function RESET_ACCOUNTINFO(state) {
    state.accountInfo = {};
  },
  SET_DEPOSITS: function SET_DEPOSITS(state, deposits) {
    state.deposits = deposits;
  },
  RESET_DEPOSITS: function RESET_DEPOSITS(state) {
    state.deposits = [];
  },
  SET_CUSTOMERINFO: function SET_CUSTOMERINFO(state, customers) {
    var contPhone = customers.contPhone;

    if (contPhone) {
      customers['callContPhone'] = contPhone;
    }

    state.customers = customers;
  },
  RESET_CUSTOMERINFO: function RESET_CUSTOMERINFO(state) {
    state.customers = {};
  },
  SET_BROADBAND: function SET_BROADBAND(state, broad) {
    state.broad = broad;
  },
  RESET_BROADBAND: function RESET_BROADBAND(state) {
    state.broad = [];
  },
  SET_EQUIPMENT: function SET_EQUIPMENT(state, equt) {
    state.equt = equt;
  },
  RESET_EQUIPMENT: function RESET_EQUIPMENT(state) {
    state.equt = [];
  },
  SET_APPLICATION: function SET_APPLICATION(state, use) {
    state.use = use;
  },
  RESET_APPLICATION: function RESET_APPLICATION(state) {
    state.use = [];
  },
  SET_USERINFOS: function SET_USERINFOS(state, userinfos) {
    var userTel = userinfos.userTel;

    if (userTel) {
      // userTel.
      userinfos['callUserTel'] = userTel;
    }

    state.userinfos = userinfos;
  },
  RESET_USERINFOS: function RESET_USERINFOS(state) {
    state.userinfos = {};
  },
  SET_RELUSERS: function SET_RELUSERS(state, relUsers) {
    state.relUsers = relUsers;
  },
  RESET_RELUSERS: function RESET_RELUSERS(state) {
    state.relUsers = [];
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
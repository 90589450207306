import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import { mapState } from 'vuex';
/**
 * 友盟的数据
 * */

export default function () {
  return {
    data: function data() {
      return {
        mixinUMengDataFun: this.getMixinUMengData.bind(this),
        mixinOpenPageTime: this.$moment().format('YYYY-MM-DD HH:mm:ss') // 进入页面的时间

      };
    },
    methods: {
      getMixinUMengData: function getMixinUMengData() {
        return _objectSpread({}, this.mixinUMengData);
      }
    },
    computed: _objectSpread({}, mapState({
      mixinLoginInfo: function mixinLoginInfo(state) {
        return state.login.loginInfo;
      }
    }), {
      // 基础数据
      mixinUMengBaseData: function mixinUMengBaseData() {
        var _this = this;

        var routeNameFun = function routeNameFun(routeRecord) {
          if (routeRecord.meta) {
            return routeRecord.meta.title || routeRecord.name || '';
          } else {
            return routeRecord.name;
          }
        }; // 获取菜单，根据菜单等级获取


        var menuNameFun = function menuNameFun() {
          var menu = {};

          _this.$route.matched.forEach(function (routeRecord, index) {
            menu["menu".concat(index)] = routeNameFun(routeRecord);
          });

          return menu;
        };

        var tempBaseInfo = _objectSpread({}, menuNameFun(), {
          // 路由名称
          componentName: this.$options.name || '',
          // 组件名称
          openPageTime: this.mixinOpenPageTime,
          // 打开页面的时间点
          sourceSystemId: 'WEB'
        });

        var loginData = {};

        if (this.mixinLoginInfo && this.mixinLoginInfo.org) {
          loginData = {
            orgName: this.mixinLoginInfo.org.orgName,
            districtName: this.mixinLoginInfo.org.district,
            regionId: this.mixinLoginInfo.org.regionId,
            operatorName: this.mixinLoginInfo.operatorName
          };
        }

        return _objectSpread({}, tempBaseInfo, {}, loginData);
      },
      // 需要添加到埋点的数据
      mixinUMengData: function mixinUMengData() {
        return _objectSpread({}, this.mixinUMengBaseData, {}, this.mixinUMengExtendData);
      }
    })
  };
}
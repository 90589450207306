var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.initPageEnd
      ? _c(
          "div",
          { staticClass: "menu-wrapper" },
          [
            !_vm.hasShowChildren
              ? [
                  typeof _vm.routeItem.children === "undefined"
                    ? _c(
                        "app-link",
                        {
                          directives: [
                            {
                              name: "um-page-button-click",
                              rawName: "v-um-page-button-click",
                              value: [
                                "功能-" + _vm.routeItem.meta.title,
                                _vm.mixinUMengBaseData
                              ],
                              expression:
                                "[`功能-${routeItem.meta.title}`,mixinUMengBaseData]"
                            }
                          ],
                          attrs: { to: _vm.toUrl }
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              class: {
                                "submenu-title-noDropdown": !_vm.isNest
                              },
                              attrs: {
                                index: _vm.resolvePath(_vm.routeItem.path)
                              }
                            },
                            [
                              _c("item", {
                                attrs: {
                                  icon:
                                    _vm.routeItem.meta.icon ||
                                    (_vm.routeItem.meta &&
                                      _vm.routeItem.meta.icon),
                                  title: _vm.routeItem.meta.title
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : _c(
                  "el-submenu",
                  {
                    ref: "subMenu",
                    attrs: {
                      index: _vm.resolvePath(
                        _vm.item.meta.title + _vm.item.path
                      ),
                      "popper-append-to-body": ""
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm.item.meta
                          ? _c("item", {
                              attrs: {
                                icon: _vm.item.meta && _vm.item.meta.icon,
                                title: _vm.item.meta.title
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.itemShowChildren, function(child, index) {
                      return _c("sidebar-item", {
                        key: child.path + index,
                        staticClass: "nest-menu",
                        attrs: {
                          "base-path": _vm.toUrl,
                          "is-nest": true,
                          item: child
                        }
                      })
                    })
                  ],
                  2
                )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find";

/* 作者：项目开发者
动态路由列表 */
import Layout from '@/layout';
import NoPageRoute from "./layout/components/NoPageRoute";
export var routerList = [{
  path: '/',
  component: Layout,
  meta: {
    title: '我的任务',
    icon: 'example'
  },
  children: [{
    path: 'callcenter/todoListsNew',
    name: 'TodoListsNew',
    component: function component() {
      return import('@/views2/NewTodoLists/todoListsNew.vue');
    },
    meta: {
      title: '我的待办',
      icon: 'example'
    }
  }, {
    path: 'callcenter/isProcessed',
    name: 'IsProcessed',
    component: function component() {
      return import('@/views2/NewTodoLists/isProcessed.vue');
    },
    meta: {
      title: '我的已办',
      icon: 'example'
    }
  }, {
    path: 'callcenter/helpDoc',
    name: 'HelpDoc',
    component: function component() {
      return import('@/views/helpDoc/index.vue');
    },
    meta: {
      title: '帮助中心',
      icon: 'example'
    }
  }, {
    path: 'callcenter/helpDocInfo',
    name: 'HelpDocInfo',
    component: function component() {
      return import('@/views/helpDoc/helpDocInfoPage.vue');
    },
    meta: {
      title: '帮助中心详情',
      icon: 'example'
    },
    hidden: true
  } // {
  //   path: 'callcenter/todoListsNew1',
  //   name: 'TodoListsNew',
  //   component: () => import('@/views2/NewTodoLists/index.vue'),
  //   meta: {
  //     hidden: true,
  //     title: '任务池',
  //     icon: 'example'
  //   }
  // }
  ]
}, {
  path: '/',
  component: Layout,
  meta: {
    title: '我的权限',
    icon: 'example',
    source: 'CHASINGHOPE'
  },
  children: [{
    path: 'reviewOrder',
    redirect: 'reviewOrder',
    component: NoPageRoute,
    meta: {
      title: '订单审核处理',
      icon: 'query'
    },
    hidden: true,
    children: [{
      path: 'reviewNew',
      name: 'ReviewNew',
      component: function component() {
        return import('@/views2/reviewOrder/reviewNew.vue');
      },
      meta: {
        title: '宽带新装审核',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'reviewTransfer',
      name: 'ReviewTransfer',
      component: function component() {
        return import('@/views2/reviewOrder/reviewTransfer.vue');
      },
      meta: {
        title: '宽带过户审核',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'reviewMoveMch',
      name: 'ReviewMoveMch',
      component: function component() {
        return import('@/views2/reviewOrder/reviewMoveMch.vue');
      },
      meta: {
        title: '宽带移机审核',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'reviewStop',
      name: 'ReviewStop',
      component: function component() {
        return import('@/views2/reviewOrder/reviewStop.vue');
      },
      meta: {
        title: '宽带停机审核',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'reviewOpen',
      name: 'ReviewOpen',
      component: function component() {
        return import('@/views2/reviewOrder/reviewOpen.vue');
      },
      meta: {
        title: '宽带复机审核',
        icon: 'table'
      },
      hidden: true
    }]
  }, {
    path: '',
    component: NoPageRoute,
    name: 'baseBusinessManagement',
    meta: {
      title: '宽带业务受理',
      icon: 'form'
    },
    children: [{
      path: 'userComprehensiveInfo/userComprehensiveInfo',
      name: 'userComprehensiveInfo',
      component: function component() {
        return import('@/views/user_comprehensive_info/index');
      },
      meta: {
        title: '用户综合查询',
        icon: 'query'
      }
    }, {
      path: 'baseBusinessManagement/networkOpening',
      name: 'NetworkOpening',
      component: function component() {
        return import('@/views/network_opening/index.vue');
      },
      meta: {
        title: '宽带新装',
        icon: 'example',
        baseBusiness: true // 业务受理

      }
    }, {
      path: 'baseBusinessManagement/BusinessOrder',
      name: 'BusinessOrder',
      component: function component() {
        return import('@/views/BusinessOrder/index');
      },
      meta: {
        title: '宽带订购',
        icon: 'table',
        auth: '宽带订购',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/OtherOders',
      name: 'OtherOders',
      component: function component() {
        return import('@/views/OtherOrders/index');
      },
      meta: {
        title: '其他订购',
        icon: 'table',
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/businessChange',
      name: 'businessChange',
      component: function component() {
        return import('@/views2/businessChange/index.vue');
      },
      meta: {
        title: '宽带业务变更',
        icon: 'community',
        auth: '宽带业务变更',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/changeOffer',
      name: 'changeOffer',
      component: function component() {
        return import('@/views/BusinessOrder/changeOffer.vue');
      },
      meta: {
        title: '宽带套餐变更',
        icon: 'community',
        auth: '宽带套餐变更',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/servicehandlingnew',
      name: 'servicehandlingnew',
      hidden: true,
      component: function component() {
        return import('@/components/public_components/NewServiceHandlingform.vue');
      },
      meta: {
        title: '新受理单',
        icon: 'table',
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/servicehandlingform',
      name: 'servicehandlingform',
      hidden: true,
      component: function component() {
        return import('@/views/network_opening/components/serviceHandlingform.vue');
      },
      meta: {
        title: '受理单',
        icon: 'table',
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/Transferform',
      name: 'Transferform',
      component: function component() {
        return import('@/views/BusinessOrder/transfer.vue');
      },
      meta: {
        title: '宽带过户',
        icon: 'tree',
        auth: '宽带过户',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/MoveMachineform',
      name: 'MoveMachineform',
      component: function component() {
        return import('@/views/BusinessOrder/moveMch.vue');
      },
      meta: {
        title: '宽带移机',
        icon: 'tree',
        auth: '宽带移机',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/UserStop',
      name: 'UserStop',
      component: function component() {
        return import('@/views/BusinessOrder/userStop.vue');
      },
      meta: {
        title: '宽带停机',
        icon: 'tree',
        auth: '宽带停机',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/batchShutdown',
      name: 'BatchShutdown',
      component: function component() {
        return import('@/views/BusinessOrder/batchShutdown.vue');
      },
      meta: {
        title: '批量停机',
        icon: 'tree',
        // auth: '批量停机',
        // nodeEnv: ['ckSubcontracting', 'ckSubcontracting_local'],
        // userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/UserOpen',
      name: 'UserOpen',
      component: function component() {
        return import('@/views/BusinessOrder/userOpen.vue');
      },
      meta: {
        title: '宽带复机',
        icon: 'tree',
        auth: '宽带复机',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/resetPassword',
      name: 'resetPassword',
      component: function component() {
        return import('@/views/resetPassword/index.vue');
      },
      meta: {
        title: '忘记密码',
        icon: 'password',
        auth: '忘记密码',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/modifyPassword',
      name: 'modifyPassword',
      component: function component() {
        return import('@/views/modifyPassword/index.vue');
      },
      hidden: true,
      meta: {
        title: '修改密码',
        icon: 'password',
        auth: '修改密码',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/cancellationUser',
      name: 'cancellationUser',
      component: function component() {
        return import('@/views/cancellationUser/index.vue');
      },
      meta: {
        title: '宽带销户',
        icon: 'cancel',
        auth: '宽带销户',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/auditCard',
      name: 'auditCard',
      component: function component() {
        return import('@/views/auditCard/index.vue');
      },
      meta: {
        title: '证件审核',
        icon: 'form',
        baseBusiness: true
      },
      hidden: true
    }, {
      path: 'baseBusinessManagement/billOnce',
      name: 'billOnce',
      component: function component() {
        return import('@/views/billOnce/index.vue');
      },
      meta: {
        title: '一次性费用',
        icon: 'community',
        auth: '一次性费用',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'baseBusinessManagement/billRecovery',
      name: 'billRecovery',
      component: function component() {
        return import('@/views/billRecovery/index.vue');
      },
      meta: {
        title: '管理停复机',
        icon: 'community',
        auth: '管理停复机',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'onlineManager/addressPoolChange',
      name: 'addressPoolChange',
      component: function component() {
        return import('@/views2/addressPoolChange/index.vue');
      },
      meta: {
        title: '地址池变更',
        icon: 'community',
        auth: '地址池变更',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'onlineManager/forceOffline',
      name: 'forceOffline',
      component: function component() {
        return import('@/views2/forceOffline/index.vue');
      },
      meta: {
        title: '强制下线',
        icon: 'community',
        auth: '强制下线',
        userspec: ['BROADBAND'],
        baseBusiness: true
      }
    }, {
      path: 'onlineManager/clearOnlineInfo',
      name: 'clearOnlineInfo',
      component: function component() {
        return import('@/views/clearOnlineInfo/index.vue');
      },
      meta: {
        title: '在线信息清理',
        icon: 'userSetting',
        auth: '在线信息清理',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'onlineManager/removeVlan',
      name: 'removeVlan',
      component: function component() {
        return import('@/views/removeVlan/index.vue');
      },
      meta: {
        title: '解绑VLAN信息',
        icon: 'userSetting',
        auth: '解绑VLAN信息',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'onlineManager/batchUnbindVlan',
      name: 'BatchUnbindVlan',
      component: function component() {
        return import('@/views/batch_unbind_vlan/index.vue');
      },
      meta: {
        title: '批量解绑VLAN',
        icon: 'log'
      }
    }, {
      path: 'unionMobile/queryPackage',
      name: 'QueryPackage',
      component: function component() {
        return import('@/views2/unionMobile/queryPackage/index.vue');
      },
      meta: {
        title: '融移业务受理',
        icon: 'query',
        baseBusiness: true
      }
    }, {
      path: 'unionMobile/addMobilePackage',
      name: 'AddMobilePackage',
      hidden: true,
      component: function component() {
        return import('@/views2/unionMobile/managementPackage/addMobilePackage.vue');
      },
      meta: {
        title: '新增融移套餐',
        baseBusiness: true
      }
    }, {
      path: 'financialManager/refund',
      name: 'Refund',
      component: function component() {
        return import('@/views/refund/index.vue');
      },
      meta: {
        title: '退费',
        icon: 'refund',
        auth: '退费',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'financialManager/deposit',
      name: 'Deposit',
      component: function component() {
        return import('@/views/deposit/index.vue');
      },
      meta: {
        title: '押金处理',
        icon: 'nested',
        auth: '押金处理',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'financialManager/cancleBooking',
      name: 'cancleBooking',
      component: function component() {
        return import('@/views2/cancleBooking/index.vue');
      },
      meta: {
        title: '业务退订',
        icon: 'community',
        auth: '业务退订',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'informationManager/changeCustom',
      name: 'ChangeCustom',
      component: function component() {
        return import('@/views/changeCustom/index.vue');
      },
      meta: {
        title: '客户信息修改',
        icon: 'userSetting',
        auth: '客户信息修改',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'informationManager/CustomerServiceChangeUser',
      name: 'CustomerServiceChangeUser',
      component: function component() {
        return import('@/views/customer_service_change_user/index.vue');
      },
      meta: {
        title: '用户信息修改',
        icon: 'customerService',
        auth: '用户信息修改',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'informationManager/changeUser',
      name: 'changeUser',
      component: function component() {
        return import('@/views/changeUser/index.vue');
      },
      meta: {
        title: '用户信息修改-后台',
        icon: 'userSetting',
        auth: '用户信息修改-后台',
        userspec: ['BROADBAND', 'EQUIPMENT', 'APPLICATION'],
        baseBusiness: true
      }
    }, {
      path: 'businessQueries/checkMonthlyBills',
      name: 'checkMonthlyBills',
      component: function component() {
        return import('@/views/checkMonthlyBills/index.vue');
      },
      meta: {
        title: '月账单查询',
        icon: 'bill'
      }
    }, {
      path: 'businessQueries/vlanQuery',
      name: 'vlanQuery',
      component: function component() {
        return import('@/views/vlanQuery/index.vue');
      },
      meta: {
        title: 'Vlan查询',
        icon: 'userSetting'
      }
    }, // 认证日志查询
    {
      path: 'businessQueries/authenticationLogQuery',
      name: 'authenticationLogQuery',
      component: function component() {
        return import('@/views/authenticationLogQuery/index.vue');
      },
      meta: {
        title: '认证日志查询',
        icon: 'log'
      }
    }, {
      path: 'businessQueries/onlineQuery',
      name: 'onlineQuery',
      component: function component() {
        return import('@/views/onLineQuery/index.vue');
      },
      meta: {
        title: '在线信息查询',
        icon: 'log'
      }
    }, {
      path: 'businessQueries/onlineIPQuery',
      name: 'onlineIPQuery',
      component: function component() {
        return import('@/views/onLineIPQuery/index.vue');
      },
      meta: {
        title: '在线IP查询',
        icon: 'log'
      }
    }, {
      path: 'businessQueries/reviewOrder/reviewList',
      name: 'ReviewList',
      component: function component() {
        return import('@/views2/reviewList/index.vue');
      },
      meta: {
        title: '订单列表查询',
        icon: 'table'
      }
    }, {
      path: 'businessQueries/bossData/historyInfo',
      name: 'HistoryInfo',
      component: function component() {
        return import('@/viewsBoss/historyInfo/index.vue');
      },
      meta: {
        title: '历史数据查询',
        icon: 'query'
      }
    }, // 宽带详单
    {
      path: 'businessQueries/broadbandDetailed',
      name: 'broadbandDetailed',
      component: function component() {
        return import('@/views/broadbandDetailed/index.vue');
      },
      hidden: true,
      meta: {
        title: '宽带详单',
        icon: 'bill'
      }
    }, // 宽带详单
    {
      path: 'businessQueries/broadbandDetailedWithMoreInfo',
      name: 'broadbandDetailedWithMoreInfo',
      component: function component() {
        return import('@/views/broadbandDetailed/indexWithMoreInfo.vue');
      },
      meta: {
        title: '宽带详单',
        icon: 'bill'
      }
    }, {
      path: 'communityManagement/communityQuery',
      name: 'CommunityQuery',
      component: function component() {
        return import('@/views/community_query/index.vue');
      },
      meta: {
        title: '社区查询',
        icon: 'community'
      }
    }, {
      path: 'communityManagement/addCommunity',
      name: 'AddCommunity',
      hidden: true,
      component: function component() {
        return import('@/views/add_community/index.vue');
      },
      meta: {
        title: '新增社区',
        icon: 'add'
      }
    }, // 修改社区
    {
      path: 'communityManagement/modifyCommunity',
      name: 'ModifyCommunity',
      component: function component() {
        return import('@/views/community_list/modifyCommunity.vue');
      },
      hidden: true,
      meta: {
        title: '修改社区',
        icon: 'community'
      }
    }, // 批量调整社区归属
    {
      path: 'communityManagement/batchAdjustmentCommunity',
      name: 'batchAdjustmentCommunity',
      component: function component() {
        return import('@/views/community_list/batchAdjustmentCommunity.vue');
      },
      hidden: true,
      meta: {
        title: '批量调整社区归属',
        icon: 'community'
      }
    }, {
      path: 'resourceManagement/importComboPackage',
      name: 'ImportComboPackage',
      component: function component() {
        return import('@/views2/combopackageManage/components/importComboPackage.vue');
      },
      meta: {
        title: '宽带套餐导入',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'resourceManagement/modifyOffTime',
      name: 'ModifyOffTime',
      component: function component() {
        return import('@/views2/combopackageManage/components/modifyOffTime.vue');
      },
      meta: {
        title: '套餐下架时间调整',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'resourceManagement/comboResourceChange',
      name: 'ComboResourceChange',
      component: function component() {
        return import('@/views2/combopackageManage/components/comboResourceChange.vue');
      },
      meta: {
        title: '套餐内资源变更',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'resourceManagement/comboNameChange',
      name: 'ComboNameChange',
      component: function component() {
        return import('@/views2/combopackageManage/components/comboNameChange.vue');
      },
      meta: {
        title: '套餐名称变更',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'resourceManagement/addEquipmentModel',
      name: 'AddEquipmentModel',
      component: function component() {
        return import('@/views/equipmentModel/addEquipmentModel.vue');
      },
      meta: {
        title: '新增资源型号'
      },
      hidden: true
    }, {
      path: 'resourceManagement/changeEquipmentModel',
      name: 'changeEquipmentModel',
      component: function component() {
        return import('@/views/equipmentModel/changeEquipmentModel.vue');
      },
      meta: {
        title: '修改资源型号'
      },
      hidden: true
    }, {
      path: 'resourceManagement/equipmentInfo',
      name: 'EquipmentInfo',
      component: function component() {
        return import('@/views/equipmentInfo/index.vue');
      },
      meta: {
        title: '资源设备信息管理',
        icon: 'info'
      }
    }, {
      path: 'resourceManagement/addEquipmentInfo',
      name: 'AddEquipmentInfo',
      component: function component() {
        return import('@/views/equipmentInfo/components/addEquipmentInfo.vue');
      },
      meta: {
        title: '新增资源信息'
      },
      hidden: true
    }, {
      path: 'resourceManagement/modifyEquipmentInfo',
      name: 'modifyEquipmentInfo',
      component: function component() {
        return import('@/views/equipmentInfo/modifyEquipmentInfo.vue');
      },
      meta: {
        title: '修改资源信息'
      },
      hidden: true
    }, {
      path: 'resourceManagement/addSale',
      name: 'addSale',
      component: function component() {
        return import('@/views/sale/components/addSale.vue');
      },
      meta: {
        title: '新增促销品'
      },
      hidden: true
    }, {
      path: 'resourceManagement/resType',
      name: 'ResType',
      component: function component() {
        return import('@/views/resType/index.vue');
      },
      hidden: true,
      meta: {
        title: '资源类型管理',
        icon: 'sale'
      }
    }, {
      path: 'resourceManagement/modifyResType',
      name: 'modifyResType',
      component: function component() {
        return import('@/views/resType/components/form.vue');
      },
      meta: {
        title: '设备类型表单',
        icon: 'sale'
      },
      hidden: true
    }, {
      path: 'resourceManagement/compositeResource',
      name: 'CompositeResource',
      component: function component() {
        return import('@/views2/compositeResource/index.vue');
      },
      meta: {
        title: '资源综合处理',
        icon: 'query'
      }
    }, {
      path: 'telemarketing/creatAction',
      name: 'creatAction',
      component: function component() {
        return import('@/views2/telemarketing/creatAction/index.vue');
      },
      meta: {
        title: '创建活动',
        icon: 'add'
      }
    }, {
      // 营销活动创建页
      path: 'telemarketing/marketingCampaign',
      name: 'marketingCampaign',
      component: function component() {
        return import('@/views2/telemarketing/creatAction/marketingCampaign.vue');
      },
      meta: {
        title: '创建营销活动',
        icon: 'add'
      },
      hidden: true
    }, {
      // 营销活动结果页
      path: 'telemarketing/creatActionRes',
      name: 'creatActionRes',
      component: function component() {
        return import('@/views2/telemarketing/creatAction/creatActionRes.vue');
      },
      meta: {
        title: '创建营销活动',
        icon: 'add'
      },
      hidden: true
    }, {
      // 电销活动列表
      path: 'telemarketing/actionList',
      name: 'actionList',
      component: function component() {
        return import('@/views2/telemarketing/actionList/index.vue');
      },
      meta: {
        title: '活动列表',
        icon: 'list'
      }
    }, // 数据确认和分配
    {
      path: 'telemarketing/dataDistribution',
      name: 'dataDistribution',
      component: function component() {
        return import('@/views2/telemarketing/manage/index.vue');
      },
      meta: {
        title: '数据确认和分配',
        icon: 'nested'
      }
    }, // 电销分单
    {
      path: 'telemarketing/telemarketingDistribution',
      name: 'telemarketingDistribution',
      component: function component() {
        return import('@/views2/telemarketing/distributionPage/index.vue');
      },
      meta: {
        title: '电销分单',
        icon: 'resources'
      }
    }, // 电销外呼
    {
      path: 'telemarketing/tmkOutcall',
      name: 'TmkOutcall',
      component: function component() {
        return import('@/views2/tmkOutcall/index.vue');
      },
      meta: {
        title: '电销外呼',
        icon: 'income'
      }
    }, {
      path: 'telemarketing/checkuserInfo',
      name: 'checkuserInfo',
      component: function component() {
        return import('@/views2/tmkOutcall/checkuserInfo.vue');
      },
      meta: {
        title: '商机',
        icon: 'bill'
      },
      hidden: true
    }, {
      // 预约时间提醒
      path: 'telemarketing/alertMessage',
      name: 'alertMessage',
      component: function component() {
        return import('@/views2/tmkOutcall/alertMessage.vue');
      },
      meta: {
        title: '预约时间提醒'
      },
      hidden: true
    }, // 套餐订购查询报表
    {
      path: 'reportQueries/offerBookedQuery',
      name: 'offerBookedQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '套餐订购明细',
        icon: 'bill'
      },
      props: {
        defineId: '8021'
      }
    }, // 订单查询报表
    {
      path: 'reportQueries/orderQuery',
      name: 'orderQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '订单查询',
        icon: 'bill'
      },
      props: {
        defineId: '8022'
      }
    }, // 账务查询报表
    {
      path: 'reportQueries/accountQuery',
      name: 'accountQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '账务查询',
        icon: 'bill'
      },
      hidden: true,
      props: {
        defineId: '13623'
      }
    }, {
      path: 'reportQueries/accountQueryNew',
      name: 'accountQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '账务查询',
        icon: 'bill'
      },
      props: {
        // reportVersion: '1',
        defineId: '13623'
      }
    }, // 账务统计
    {
      path: 'reportQueries/financialTj',
      name: 'financialTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '账务统计',
        icon: 'bill'
      },
      hidden: true,
      props: {
        defineId: '13624'
      }
    }, // 账务统计（新）
    {
      path: 'reportQueries/financialTjNew',
      name: 'financialTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '账务统计',
        icon: 'bill'
      },
      props: {
        // reportVersion: '1',
        defineId: '13624'
      }
    }, // 权责明细查询报表
    {
      path: 'reportQueries/billingQuery',
      name: 'billingQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '权责明细查询',
        icon: 'bill'
      },
      props: {
        defineId: '14430'
      }
    }, // 权责统计
    {
      path: 'reportQueries/quanzeTj',
      name: 'quanzeTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '权责统计',
        icon: 'bill'
      },
      props: {
        defineId: '14431'
      }
    }, // 押金余额查询报表
    {
      path: 'reportQueries/yajinQuery',
      name: 'yajinQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '押金余额查询',
        icon: 'bill'
      },
      props: {
        defineId: '8020'
      }
    }, // 押金余额统计
    {
      path: 'reportQueries/depositTj',
      name: 'depositTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '押金余额统计',
        icon: 'bill',
        auth: '押金余额统计'
      },
      props: {
        defineId: '8025'
      }
    }, // 已用资源明细报表
    {
      path: 'reportQueries/resQuery',
      name: 'resQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '已用资源明细',
        icon: 'bill'
      },
      props: {
        defineId: '14855'
      }
    }, // 已用资源统计报表
    {
      path: 'reportQueries/resTj',
      name: 'resTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '已用资源统计',
        icon: 'bill'
      },
      props: {
        defineId: '14856'
      }
    }, // 用户信息查询
    {
      path: 'reportQueries/userinfoQuery',
      name: 'userinfoQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '用户信息查询',
        icon: 'bill'
      },
      props: {
        defineId: '13824'
      }
    }, // 用户信息统计
    {
      path: 'reportQueries/userinfoTj',
      name: 'userinfoTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '用户信息统计',
        icon: 'bill'
      },
      props: {
        defineId: '13825'
      }
    }, // 已用促销品明细
    {
      path: 'reportQueries/marketDetail',
      name: 'marketDetail',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '已用促销品明细',
        icon: 'bill'
      },
      props: {
        defineId: '18000'
      }
    }, // 已用促销品统计
    {
      path: 'reportQueries/marketTj',
      name: 'marketTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '已用促销品统计',
        icon: 'bill'
      },
      props: {
        defineId: '18001'
      }
    }, // 套餐概要查询
    {
      path: 'reportQueries/offerGy',
      name: 'offerGy',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '套餐概要查询',
        icon: 'bill'
      },
      props: {
        defineId: '18003'
      }
    }, // 操作员角色查询
    {
      path: 'reportQueries/operatorCx',
      name: 'operatorCx',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '操作员角色查询',
        icon: 'bill'
      },
      props: {
        defineId: '18002'
      }
    }, // 权责ARPU值月报
    {
      path: 'reportQueries/MonthlyARPU',
      name: 'MonthlyARPU',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '权责ARPU月报',
        icon: 'bill'
      },
      props: {
        defineId: '18006'
      }
    }, // 个宽经营月报
    {
      path: 'reportQueries/monthlyReport',
      name: 'monthlyReport',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '个宽经营月报',
        icon: 'bill'
      },
      props: {
        defineId: '18007'
      }
    }, // 终端设备绑定明细
    {
      path: 'reportQueries/terminalDetails',
      name: 'TerminalDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '终端设备解绑明细',
        icon: 'bill'
      },
      props: {
        defineId: '18004'
      }
    }, {
      path: 'reportQueries/channelQuery',
      name: 'channelQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '上户渠道日报',
        icon: 'bill'
      },
      props: {
        defineId: '18008'
      }
    }, {
      path: 'reportQueries/channelMonthQuery',
      name: 'channelMonthQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '上户渠道月报',
        icon: 'bill'
      },
      props: {
        defineId: '18009'
      }
    }, // 订单审核明细
    {
      path: 'reportQueries/orderReviewDetails',
      name: 'OrderReviewDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '订单审核明细',
        icon: 'bill'
      },
      props: {
        defineId: '13827'
      }
    }, // 套餐订购查询报表
    {
      path: 'reportQueries/moviesSellQuery',
      name: 'moviesSellQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '影视直播绑定售卖明细',
        icon: 'bill'
      },
      props: {
        defineId: '13826'
      }
    }, // 用户标签查询
    {
      path: 'reportQueries/userTagQuery',
      name: 'userTagQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '用户标签查询',
        icon: 'bill'
      },
      props: {
        defineId: '13830'
      }
    }, // 关停操作员查询
    {
      path: 'reportQueries/operatorActivityMonitor',
      name: 'operatorActivityMonitor',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '关停操作员查询',
        icon: 'bill'
      },
      props: {
        defineId: '13829'
      }
    }, {
      path: 'reportQueries/unionMobileActivationDetails',
      name: 'unionMobileActivationDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '融移激活查询',
        icon: 'bill'
      },
      props: {
        defineId: '18015'
      }
    }]
  }, {
    path: '',
    component: NoPageRoute,
    name: 'financialManager',
    meta: {
      title: '宽带业务管理',
      icon: 'form'
    },
    children: [{
      path: 'financialManager/OrderInquiry',
      name: 'OrderInquiry',
      component: function component() {
        return import('@/views/OrderInquiry/index.vue');
      },
      meta: {
        title: '订单查询处理',
        icon: 'nested'
      }
    }, {
      path: 'financialManager/transferUserInfo4user',
      name: 'TransferUserInfo',
      component: function component() {
        return import('@/views2/transferUserInfo/transferUserInfo4user.vue');
      },
      meta: {
        title: '转网处理（用户维度）',
        icon: 'table'
      }
    }, {
      path: 'financialManager/transferUserInfo4business',
      name: 'TransferUserInfo',
      component: function component() {
        return import('@/views2/transferUserInfo/transferUserInfo4business.vue');
      },
      meta: {
        title: '转网处理（业务维度）',
        icon: 'table'
      }
    }, {
      path: 'financialManager/userInfoDetailList',
      name: 'TransferUserInfo',
      component: function component() {
        return import('@/views2/transferUserInfo/userInfoDetailList.vue');
      },
      meta: {
        title: '转网用户明细查询',
        icon: 'table'
      }
    }, {
      path: 'financialManager/adjustBill',
      name: 'AdjustBill',
      component: function component() {
        return import('@/views/adjust_bill/index.vue');
      },
      meta: {
        title: '调账',
        icon: 'income',
        auth: '调账',
        userspec: ['BROADBAND', 'APPLICATION']
      }
    }, {
      path: 'financialManager/income',
      name: 'Income',
      component: function component() {
        return import('@/views/income/index.vue');
      },
      meta: {
        title: '转收入',
        icon: 'income',
        auth: '转收入',
        userspec: ['BROADBAND', 'APPLICATION']
      }
    }, {
      path: 'resourceManagement/createcombopackage',
      name: 'CreateComboPackage',
      component: function component() {
        return import('@/views2/createComboPackage/index.vue');
      },
      meta: {
        title: '组合套餐管理',
        icon: 'log'
      }
    }, {
      path: 'resourceManagement/offerSaleManage',
      name: 'OfferSaleManage',
      component: function component() {
        return import('@/views2/offerSaleManage/index.vue');
      },
      meta: {
        title: '销售套餐限制',
        icon: 'log'
      }
    }, // 批量修改用户标签
    {
      path: 'financialManager/batchModifyUserTag',
      name: 'BatchModifyUserTag',
      component: function component() {
        return import('@/views2/batch/modifyUserTag/index.vue');
      },
      meta: {
        title: '批量修改用户标签',
        icon: 'community'
      }
    }, {
      path: 'communityManagement/communityList',
      name: 'CommunityList',
      component: function component() {
        return import('@/views/community_list/index.vue');
      },
      meta: {
        title: '社区管理',
        icon: 'list',
        auth: '社区管理'
      }
    }, {
      path: 'resourceManagement/combopackageManage',
      name: 'combopackageManage',
      component: function component() {
        return import('@/views2/combopackageManage/index.vue');
      },
      meta: {
        title: '套餐管理',
        icon: 'log'
      }
    }, {
      path: 'unionMobile/managementPackage',
      name: 'ManagementPackage',
      component: function component() {
        return import('@/views2/unionMobile/managementPackage/index.vue');
      },
      meta: {
        title: '融移套餐管理(后台)',
        icon: 'resources'
      }
    }, {
      path: 'resourceManagement/equipmentModel',
      name: 'EquipmentModel',
      component: function component() {
        return import('@/views/equipmentModel/index.vue');
      },
      meta: {
        title: '资源设备型号管理',
        icon: 'equipment'
      }
    }, {
      path: 'resourceManagement/sale',
      name: 'sale',
      component: function component() {
        return import('@/views/sale/index.vue');
      },
      meta: {
        title: '增值促销品管理',
        icon: 'sale'
      }
    }, {
      path: 'resourceManagement/resType',
      name: 'ResType',
      component: function component() {
        return import('@/views/resType/index.vue');
      },
      meta: {
        title: '资源类型管理',
        icon: 'sale'
      }
    }]
  }, {
    path: '',
    component: NoPageRoute,
    name: 'bossinformation',
    meta: {
      title: '宽带工单管理',
      icon: 'form'
    },
    children: [{
      path: 'callcenter/potentialUserTable',
      name: 'potentialUserTable',
      component: function component() {
        return import('@/views2/bossinformation/components/PotentialUserTable.vue');
      },
      meta: {
        title: '潜在用户',
        icon: 'example'
      }
    }, {
      path: 'callcenter/createPropaganda',
      name: 'CreatePropaganda',
      component: function component() {
        return import('@/views2/createPropaganda/index.vue');
      },
      meta: {
        title: '宣传工单',
        icon: 'table'
      }
    }, {
      path: 'callcenter/propagandaOrderList',
      name: 'PropagandaOrderList',
      component: function component() {
        return import('@/views2/propagandaOrderList/index.vue');
      },
      meta: {
        title: '宣传工单查看',
        icon: 'table'
      }
    }, {
      path: 'callcenter/reviewPropagandaOrder',
      name: 'ReviewPropagandaOrder',
      component: function component() {
        return import('@/views2/reviewPropagandaOrder/index.vue');
      },
      meta: {
        title: '宣传工单审核页面',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/viewPropagandaOrder',
      name: 'ViewPropagandaOrder',
      component: function component() {
        return import('@/views2/viewPropagandaOrder/index.vue');
      },
      meta: {
        title: '查看宣传工单审核',
        icon: 'table'
      },
      hidden: true
    }, // ========================================
    // Boss信息查询
    {
      path: 'callcenter/workOrderAcceptance',
      name: 'WorkOrderAcceptance',
      component: function component() {
        return import('@/views2/bossinformation/index.vue');
      },
      meta: {
        title: '工单受理',
        icon: 'table'
      }
    }, {
      path: 'callcenter/historydetail/:type',
      name: 'HistoryDetail',
      component: function component() {
        return import('@/views2/historyDetail/index.vue');
      },
      meta: {
        title: '历史工单详情',
        icon: 'table'
      },
      hidden: true
    }, // {
    //   path: 'orderPage/:type',
    //   name: 'OrderPage',
    //   component: () => import('@/views2/orderPage/index.vue'),
    //   meta: { title: '工单', icon: 'table' },
    //   hidden: true
    // },
    {
      path: 'callcenter/business',
      name: 'Business',
      component: function component() {
        return import('@/views2/orderPage/orders/business.vue');
      },
      meta: {
        title: '业务工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/complaints',
      name: 'Complaints',
      component: function component() {
        return import('@/views2/orderPage/orders/complaints.vue');
      },
      meta: {
        title: '投诉工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/advisory',
      name: 'Advisory',
      component: function component() {
        return import('@/views2/orderPage/orders/advisory.vue');
      },
      meta: {
        title: '咨询工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/malfunction',
      name: 'Malfunction',
      component: function component() {
        return import('@/views2/orderPage/orders/malfunction.vue');
      },
      meta: {
        title: '故障工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/quality',
      name: 'Quality',
      component: function component() {
        return import('@/views2/orderPage/orders/quality.vue');
      },
      meta: {
        title: '质量工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/refundOrder',
      name: 'RefundOrder',
      component: function component() {
        return import('@/views2/orderPage/orders/refundOrder.vue');
      },
      meta: {
        title: '退费工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/relocation',
      name: 'Relocation',
      component: function component() {
        return import('@/views2/orderPage/orders/relocation.vue');
      },
      meta: {
        title: '移机工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/appreciation',
      name: 'Appreciation',
      component: function component() {
        return import('@/views2/orderPage/orders/appreciation.vue');
      },
      meta: {
        title: '增值工单',
        icon: 'table'
      },
      hidden: true
    }, {
      path: 'callcenter/renewal',
      name: 'RenewalPage',
      component: function component() {
        return import('@/views2/renewal/renewal.vue');
      },
      meta: {
        title: '个宽续费工单',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'callcenter/networkDiagnosis',
      name: 'networkDiagnosis',
      component: function component() {
        return import('@/views2/networkDiagnosis/index.vue');
      },
      meta: {
        title: '拨号诊断',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'callcenter/telemarketingrenewal',
      name: 'Telemarketingrenewal',
      component: function component() {
        return import('@/views2/renewal/telemarketingrenewal.vue');
      },
      meta: {
        title: '个宽电销续费工单',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'callcenter/openNew',
      name: 'OpenNew',
      component: function component() {
        return import('@/views2/openNew/index.vue');
      },
      meta: {
        title: '个宽新开工单',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'callcenter/shoppingMallOpening',
      name: 'ShoppingMallOpening',
      component: function component() {
        return import('@/views2/shoppingMallOpening/index.vue');
      },
      meta: {
        title: '商城新开',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'callcenter/materialQuery',
      name: 'MaterialQuery',
      component: function component() {
        return import('@/views2/materialQuery/index.vue');
      },
      meta: {
        title: '物料查询',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'callcenter/historyDetailTable',
      name: 'HistoryDetailTable',
      component: function component() {
        return import('@/views2/historyDetailTable/index.vue');
      },
      meta: {
        title: '工单查询',
        icon: 'example'
      }
    }, {
      path: 'callcenter/workOrder4Manager',
      name: 'workOrder4Manager',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Manager.vue');
      },
      meta: {
        title: '工单查询-新',
        icon: 'example'
      }
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4RefundView',
      name: 'workOrder4RefundView',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Refund.vue');
      },
      meta: {
        title: '申请退费工单',
        icon: 'example',
        editor: []
      },
      hidden: true
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4Refund',
      name: 'workOrder4Refund',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Refund.vue');
      },
      meta: {
        title: '申请退费工单',
        icon: 'example',
        editor: ['appointDate', 'userRequest']
      },
      hidden: true
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4RefundAppointDate',
      name: 'workOrder4RefundAppointDate',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Refund.vue');
      },
      meta: {
        title: '申请退费工单',
        icon: 'example',
        editor: ['appointDate']
      },
      hidden: true
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4RefundAppointDateHandleResult',
      name: 'workOrder4RefundAppointDateHandleResult',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Refund.vue');
      },
      meta: {
        title: '申请退费工单',
        icon: 'example',
        editor: ['appointDate', 'handleResult']
      },
      hidden: true
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4RefundUserRequestDateHandleResult',
      name: 'workOrder4RefundUserRequestDateHandleResult',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Refund.vue');
      },
      meta: {
        title: '申请退费工单',
        icon: 'example',
        editor: ['userRequest', 'handleResult']
      },
      hidden: true
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4Distribution',
      name: 'WorkOrder4Distribution',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Distribution.vue');
      },
      meta: {
        title: '配送工单',
        icon: 'example',
        editor: ['appointDate']
      },
      hidden: true
    }, {
      // 'appointDate', 'userRequest','handleResult'
      path: 'callcenter/workOrder4DistributionView',
      name: 'WorkOrder4DistributionView',
      component: function component() {
        return import('@/views2/workOrderPage/workOrder4Distribution.vue');
      },
      meta: {
        title: '配送工单',
        icon: 'example',
        editor: []
      },
      hidden: true
    }, {
      path: 'monitorCenter/monitorCenterNew',
      name: 'MonitorCenterNew',
      component: function component() {
        return import('@/views2/monitorCenter/indexNew.vue');
      },
      meta: {
        title: '工单监控',
        icon: 'example'
      }
    }, {
      path: 'creatNotsingle/creatNotsingle',
      name: 'CreatNotsingle',
      component: function component() {
        return import('@/views2/creatNotsingle/index.vue');
      },
      meta: {
        title: '创建非单',
        icon: 'example'
      }
    }, {
      path: 'creatNotsingle/receivingNotsingle',
      name: 'ReceivingNotsingle',
      component: function component() {
        return import('@/views2/receivingNotsingle/index.vue');
      },
      meta: {
        title: '非单处理',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'creatNotsingle/searchNosingle',
      name: 'SearchNosingle',
      component: function component() {
        return import('@/views2/searchNosingle/index.vue');
      },
      meta: {
        title: '非单列表',
        icon: 'example'
      },
      hidden: false
    }, {
      path: 'monitorCenter/monitorCenterProcess',
      name: 'MonitorCenterProcess',
      component: function component() {
        return import('@/views2/monitorCenter/indexProcess.vue');
      },
      meta: {
        title: '异常单处理',
        icon: 'example'
      },
      hidden: true
    }, {
      path: 'privilegeManagement/operator/employee_manage',
      name: 'EmployeeManager',
      component: function component() {
        return import('@/views/employee_manage/index.vue');
      },
      meta: {
        title: '网格管理',
        icon: 'example'
      }
    }, {
      path: 'privilegeManagement/operator/employee_schedule',
      name: 'EmployeeSchedule',
      component: function component() {
        return import('@/views/employee_schedule/index.vue');
      },
      meta: {
        title: '员工休假管理',
        icon: 'example'
      }
    }, // 工单报表查询
    {
      path: 'reportQueries/workorderQuery',
      name: 'WorkorderQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '工单报表查询',
        icon: 'bill'
      },
      props: {
        defineId: '19003'
      }
    }, {
      path: 'reportQueries/workorderQueryNew',
      name: 'WorkorderQueryNew',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '工单明细查询（新）',
        icon: 'bill'
      },
      props: {
        reportVersion: '2',
        defineId: '19003'
      }
    }, // 工单操作明细
    {
      path: 'reportQueries/workorderDetails',
      name: 'WorkorderDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '工单操作明细',
        icon: 'bill'
      },
      props: {
        defineId: '19002'
      }
    }, {
      path: 'reportQueries/workorderDetailsNew',
      name: 'WorkorderDetailsNew',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '工单操作明细（新）',
        icon: 'bill'
      },
      props: {
        reportVersion: '2',
        defineId: '19002'
      }
    }, // 非单报表查询
    {
      path: 'reportQueries/massWorkorderQuery',
      name: 'MassWorkorderQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '非单报表查询',
        icon: 'bill'
      },
      props: {
        defineId: '19004'
      }
    }, // 非单操作明细
    {
      path: 'reportQueries/massWorkorderDetails',
      name: 'MassWorkorderDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '非单操作明细',
        icon: 'bill'
      },
      props: {
        defineId: '19005'
      }
    }, // 工单销售查询
    {
      path: 'reportQueries/workorderSale',
      name: 'WorkorderSale',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '工单销售查询',
        icon: 'bill'
      },
      props: {
        defineId: '19001'
      }
    }, // 物料登记明细
    {
      path: 'reportQueries/materialDetails',
      name: 'MaterialDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '物料登记明细',
        icon: 'bill'
      },
      props: {
        defineId: '19007'
      }
    }, // 宣传工单明细导出
    {
      path: 'reportQueries/publicityWorkorderDetails',
      name: 'PublicityWorkorderDetails',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '宣传工单明细导出',
        icon: 'bill'
      },
      props: {
        defineId: '19006'
      }
    }]
  }, {
    path: 'informationManage',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '信息管理',
      icon: 'form'
    },
    children: [{
      path: 'queryInformation',
      name: 'QueryInformation',
      component: function component() {
        return import('@/views2/informationManage/index.vue');
      },
      meta: {
        title: '短信发送查询',
        icon: 'example'
      }
    }, {
      path: 'networkDiagnostics',
      name: 'NetworkDiagnostics',
      component: function component() {
        return import('@/views2/informationManage/networkDiagnostics.vue');
      },
      meta: {
        title: '上网日志查询',
        icon: 'example'
      }
    }]
  }, {
    path: 'financialAdmin',
    component: NoPageRoute,
    meta: {
      title: '财务资金管理',
      icon: 'form'
    },
    children: [{
      path: 'supplierManage',
      name: 'supplierManage',
      component: function component() {
        return import('@/views2/financialAdmin/supplierManage.vue');
      },
      meta: {
        title: '供应商管理',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'supplierDetail',
      name: 'SupplierDetail',
      component: function component() {
        return import('@/views2/financialAdmin/supplierDetail.vue');
      },
      hidden: true,
      meta: {
        title: '供应商详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'fatherSupplierDetail',
      name: 'FatherSupplierDetail',
      component: function component() {
        return import('@/views2/financialAdmin/supplierDetail.vue');
      },
      hidden: true,
      meta: {
        title: '父供应商详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'addSupplier',
      name: 'AddSupplier',
      component: function component() {
        return import('@/views2/financialAdmin/addSupplier.vue');
      },
      hidden: true,
      meta: {
        title: '新增供应商',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'goodsManage',
      name: 'GoodsManage',
      component: function component() {
        return import('@/views2/financialAdmin/goodsManage/index.vue');
      },
      meta: {
        title: '商品管理',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'goodsDetail',
      name: 'GoodsDetail',
      hidden: true,
      component: function component() {
        return import('@/views2/financialAdmin/goodsManage/goodsDetail.vue');
      },
      meta: {
        title: '商品详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'addGoods',
      name: 'AddGoods',
      component: function component() {
        return import('@/views2/financialAdmin/goodsManage/addGoods.vue');
      },
      hidden: true,
      meta: {
        title: '新增商品',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'purchaseManage',
      name: 'purchaseManage',
      component: function component() {
        return import('@/views2/financialAdmin/purchaseManage/index.vue');
      },
      meta: {
        title: '立项管理',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'purchaseDetail',
      name: 'purchaseDetail',
      component: function component() {
        return import('@/views2/financialAdmin/purchaseManage/purchaseDetail.vue');
      },
      hidden: true,
      meta: {
        title: '立项详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'purchaseApply',
      name: 'PurchaseApply',
      component: function component() {
        return import('@/views2/financialAdmin/purchaseManage/purchaseApply.vue');
      },
      hidden: true,
      meta: {
        title: '立项申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'contractManage',
      name: 'contractManage',
      component: function component() {
        return import('@/views2/financialAdmin/contractManage/index.vue');
      },
      meta: {
        title: '合同管理',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'contractDetail',
      name: 'contractDetail',
      component: function component() {
        return import('@/views2/financialAdmin/contractManage/contractDetail.vue');
      },
      hidden: true,
      meta: {
        title: '合同详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'addContract',
      name: 'AddContract',
      component: function component() {
        return import('@/views2/financialAdmin/contractManage/addContract.vue');
      },
      hidden: true,
      meta: {
        title: '合同申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'paymentReimburse',
      name: 'PaymentReimburse',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/paymentReimburse.vue');
      },
      hidden: true,
      meta: {
        title: '费用申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'reimburseDetail',
      name: 'reimburseDetail',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/reimburseDetail.vue');
      },
      hidden: true,
      meta: {
        title: '费用详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'travelExpensesReimburse',
      name: 'TravelExpensesReimburse',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/travelExpensesReimburse.vue');
      },
      hidden: true,
      meta: {
        title: '差旅费报销申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'travelExpensesReimburseDetail',
      name: 'travelExpensesReimburseDetail',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/travelExpensesReimburseDetail.vue');
      },
      hidden: true,
      meta: {
        title: '差旅费报销详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'contractExecutionManage',
      name: 'contractExecutionManage',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/index.vue');
      },
      meta: {
        title: '合同执行',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'installmentPayDetail',
      name: 'installmentPayDetail',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/installmentPayDetail.vue');
      },
      hidden: true,
      meta: {
        title: '分期付款详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'stagePayDetail',
      name: 'stagePayDetail',
      component: function component() {
        return import('@/views2/financialAdmin/contractExecution/stagePayDetail.vue');
      },
      hidden: true,
      meta: {
        title: '按执行进度付款详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, // @/views2/financialAdmin/budgetManage/index.vue
    {
      path: 'budgetManage',
      name: 'budgetManage',
      component: function component() {
        return import('@/views2/financialAdmin/budgetManage/index.vue');
      },
      meta: {
        title: '预算管理',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'budgetApply',
      name: 'budgetApply',
      component: function component() {
        return import('@/views2/financialAdmin/budgetManage/budgetApply.vue');
      },
      hidden: true,
      meta: {
        title: '预算申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'budgetDetail',
      name: 'budgetDetail',
      component: function component() {
        return import('@/views2/financialAdmin/budgetManage/budgetDetail.vue');
      },
      hidden: true,
      meta: {
        title: '预算详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'businessApplyForm',
      name: 'BusinessApplyForm',
      component: function component() {
        return import('@/views2/financialAdmin/contractManage/businessApplyForm.vue');
      },
      hidden: true,
      meta: {
        title: '业务申请单',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'expenseApplication',
      name: 'expenseApplication',
      component: function component() {
        return import('@/views2/financialAdmin/expenseApplication/index.vue');
      },
      meta: {
        title: '支出申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'batchPaymentApply',
      name: 'BatchPaymentApply',
      component: function component() {
        return import('@/views2/financialAdmin/expenseApplication/batchPaymentApply.vue');
      },
      hidden: true,
      meta: {
        title: '批量支出申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'batchPaymentDetail',
      name: 'batchPaymentDetail',
      component: function component() {
        return import('@/views2/financialAdmin/expenseApplication/batchPaymentDetail.vue');
      },
      hidden: true,
      meta: {
        title: '支出单详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'expenditureManage',
      name: 'expenditureManage',
      component: function component() {
        return import('@/views2/financialAdmin/expenditureManage/index.vue');
      },
      meta: {
        title: '付款管理',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'expenditureApply',
      name: 'ExpenditureApply',
      component: function component() {
        return import('@/views2/financialAdmin/expenditureManage/expenditureApply.vue');
      },
      hidden: true,
      meta: {
        title: '支出申请',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'expenditureDetail',
      name: 'expenditureDetail',
      component: function component() {
        return import('@/views2/financialAdmin/expenditureManage/expenditureDetail.vue');
      },
      hidden: true,
      meta: {
        title: '付款单详情',
        icon: 'example',
        source: 'PURCHASE'
      }
    }, {
      path: 'budgetDetailQuery',
      name: 'budgetDetailQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '预算明细查询',
        icon: 'bill',
        source: 'PURCHASE'
      },
      props: {
        businessType: 'staffOrgTree',
        defineId: '18010'
      }
    }, {
      path: 'executeDetailQuery',
      name: 'executeDetailQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '执行明细查询',
        icon: 'bill',
        source: 'PURCHASE'
      },
      props: {
        businessType: 'staffOrgTree',
        defineId: '18012'
      }
    }]
  }, {
    path: 'HRManagement',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '劳动关系管理',
      icon: 'form'
    },
    children: [{
      path: 'staffManage',
      name: 'staffManage',
      component: function component() {
        return import('@/views2/HRManagement/staffManage/index.vue');
      },
      meta: {
        title: '员工管理',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'staffBusinessManage',
      name: 'staffBusinessManage',
      component: function component() {
        return import('@/views2/HRManagement/staffBusinessManage/index.vue');
      },
      meta: {
        title: '人事流程',
        icon: 'example',
        source: 'STAFF'
      }
    }]
  }, {
    path: 'HRManagement',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '薪酬福利管理',
      icon: 'form'
    },
    children: [{
      path: 'salaryArchives',
      name: 'SalaryArchives',
      component: function component() {
        return import('@/views2/HRManagement/salaryArchives/index.vue');
      },
      meta: {
        title: '薪资档案',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: false
    }, {
      path: 'kpiDefinition',
      name: 'KpiDefinition',
      component: function component() {
        return import('@/views2/HRManagement/kpiDefinition/index.vue');
      },
      meta: {
        title: '数据上传',
        icon: 'example',
        source: 'SALARY'
      }
    }, {
      path: 'kpiDefinitionManagePage',
      name: 'KpiDefinitionManagePage',
      component: function component() {
        return import('@/views2/HRManagement/kpiDefinition/manage.vue');
      },
      meta: {
        title: '数据流程查询',
        icon: 'example',
        source: 'SALARY'
      }
    }, {
      path: 'salaryScheme',
      name: 'SalaryScheme',
      component: function component() {
        return import('@/views2/HRManagement/salary/index.vue');
      },
      meta: {
        title: '薪资模板',
        icon: 'example',
        source: 'SALARY'
      }
    }, {
      path: 'kpiSalaryDefinitionPage',
      name: 'KpiSalaryDefinitionPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/index.vue');
      },
      meta: {
        title: '薪资计算',
        icon: 'example',
        source: 'SALARY'
      }
    }, {
      path: 'kpiSalaryDefinitionManagePage',
      name: 'KpiSalaryDefinitionManagePage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/manage.vue');
      },
      meta: {
        title: '薪资流程查询',
        icon: 'example',
        type: 'staffView',
        source: 'SALARY'
      }
    }]
  }, {
    path: 'HRManagement',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '人力资源管理',
      icon: 'form'
    },
    children: [{
      path: 'entryApplication',
      name: 'EntryApplication',
      component: function component() {
        return import('@/views2/HRManagement/employApply/index.vue');
      },
      hidden: true,
      meta: {
        title: '入职申请',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'resignApplication',
      name: 'ResignApplication',
      component: function component() {
        return import('@/views2/HRManagement/leaveApply/index.vue');
      },
      hidden: true,
      meta: {
        title: '离职申请',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'formalApply',
      name: 'formalApply',
      component: function component() {
        return import('@/views2/HRManagement/formalApply/formalApply.vue');
      },
      hidden: true,
      meta: {
        title: '转正申请',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'transferApply',
      name: 'transferApply',
      component: function component() {
        return import('@/views2/HRManagement/transferApply/transferApply.vue');
      },
      hidden: true,
      meta: {
        title: '调动申请',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'fillInStaffInfo',
      name: 'fillInStaffInfo',
      component: function component() {
        return import('@/views2/HRManagement/staffManage/fillInStaffInfo.vue');
      },
      hidden: true,
      meta: {
        title: '入职登记表',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'contractSigning',
      name: 'contractSigning',
      component: function component() {
        return import('@/views2/HRManagement/staffManage/contractSigning.vue');
      },
      hidden: true,
      meta: {
        title: '合同签订',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'allocateAccount',
      name: 'allocateAccount',
      component: function component() {
        return import('@/views2/HRManagement/employApply/entryDetail.vue');
      },
      hidden: true,
      meta: {
        title: '分配长鸿系统账号',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'staffDetail',
      name: 'staffDetail',
      component: function component() {
        return import('@/views2/HRManagement/staffManage/staffDetailInfo.vue');
      },
      hidden: true,
      meta: {
        title: '员工详情',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'entryDetail',
      name: 'entryDetail',
      component: function component() {
        return import('@/views2/HRManagement/employApply/entryDetail.vue');
      },
      hidden: true,
      meta: {
        title: '入职详情',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'resignDetail',
      name: 'resignDetail',
      component: function component() {
        return import('@/views2/HRManagement/leaveApply/resignDetail.vue');
      },
      hidden: true,
      meta: {
        title: '离职详情',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'formalApplyDetail',
      name: 'formalApplyDetail',
      component: function component() {
        return import('@/views2/HRManagement/formalApply/formalApplyDetail.vue');
      },
      hidden: true,
      meta: {
        title: '转正详情',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'transferApplyDetail',
      name: 'TransferApplyDetail',
      component: function component() {
        return import('@/views2/HRManagement/transferApply/transferApplyDetail.vue');
      },
      hidden: true,
      meta: {
        title: '调动详情',
        icon: 'example',
        source: 'STAFF'
      }
    }, {
      path: 'salaryDetail',
      name: 'salaryDetail',
      component: function component() {
        return import('@/views2/HRManagement/salary/addScheme.vue');
      },
      hidden: true,
      meta: {
        title: '薪资详情',
        icon: 'example',
        hidden: true,
        source: 'SALARY'
      }
    }, {
      path: 'addScheme',
      name: 'addScheme',
      hidden: true,
      component: function component() {
        return import('@/views2/HRManagement/salary/addScheme.vue');
      },
      meta: {
        title: '新增方案',
        icon: 'example',
        hidden: true,
        source: 'SALARY'
      }
    }, {
      path: 'kpiCalculationDataPage',
      name: 'KpiCalculationDataPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiDefinition/calculationData.vue');
      },
      meta: {
        title: '数据计算',
        icon: 'example',
        type: 'edit',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiCalculationDataViewPage',
      name: 'KpiCalculationDataViewPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiDefinition/calculationData.vue');
      },
      meta: {
        title: '数据计算',
        icon: 'example',
        type: 'view',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiDefinitionBusinessApplyPage',
      name: 'kpiDefinitionBusinessApplyPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiDefinition/kpiDefinitionBusinessApply.vue');
      },
      meta: {
        title: '发起审批',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiDefinitionBusinessInfoPage',
      name: 'KpiDefinitionBusinessInfoPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiDefinition/kpiDefinitionBusinessInfo.vue');
      },
      meta: {
        title: '指标详情',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'salaryArchivesDetail',
      name: 'SalaryArchivesDetail',
      component: function component() {
        return import('@/views2/HRManagement/salaryArchives/salaryArchivesDetail.vue');
      },
      meta: {
        title: '薪资档案详情',
        icon: 'example',
        hidden: true,
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'buildSalaryTaskPage',
      name: 'BuildSalaryTaskPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/buildSalaryTask.vue');
      },
      meta: {
        title: '创建算薪任务',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'dataVerification',
      name: 'dataVerification',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/dataVerification.vue');
      },
      meta: {
        title: '数据核验',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiSalaryBusinessApply',
      name: 'kpiSalaryBusinessApply',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/kpiSalaryBusinessApply.vue');
      },
      meta: {
        title: '薪资审批',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiSalaryCalculationDataPage',
      name: 'KpiSalaryCalculationDataPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/kpiSalaryCalculationData.vue');
      },
      meta: {
        title: '薪资计算',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiSalaryCalculationDataViewPage',
      name: 'KpiSalaryCalculationDataViewPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/kpiSalaryCalculationData.vue');
      },
      meta: {
        title: '薪资计算',
        icon: 'example',
        type: 'view',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'kpiSalaryCalculationDataStaffViewPage',
      name: 'KpiSalaryCalculationDataStaffViewPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/kpiSalaryCalculationData.vue');
      },
      meta: {
        title: '薪资计算',
        icon: 'example',
        type: 'staffView',
        source: 'SALARY'
      },
      hidden: true
    }, {
      path: 'salaryBusinessOrderApplyPage',
      name: 'SalaryBusinessOrderApplyPage',
      component: function component() {
        return import('@/views2/HRManagement/kpiSalaryDefinition/salaryBusinessOrderApply.vue');
      },
      meta: {
        title: '薪资审批',
        icon: 'example',
        source: 'SALARY'
      },
      hidden: true
    }]
  }, {
    path: 'privilegeManagement',
    component: NoPageRoute,
    name: 'privilegeManagement',
    meta: {
      title: '组织权限管理',
      icon: 'form'
    },
    children: [{
      path: 'operator',
      name: 'operator',
      component: function component() {
        return import('@/views/authority/operator.vue');
      },
      meta: {
        title: '操作员管理',
        icon: 'example'
      }
    }, {
      path: 'OrganizationManage',
      name: 'OrganizationManage',
      component: function component() {
        return import('@/views/organization_manage/index.vue');
      },
      meta: {
        title: '组织管理',
        icon: 'income'
      }
    }, {
      path: 'role',
      name: 'role',
      component: function component() {
        return import('@/views/authority/role.vue');
      },
      meta: {
        title: '角色管理',
        icon: 'nested'
      }
    }]
  }, {
    path: 'HRPermissionManagement',
    component: NoPageRoute,
    name: 'HRPermissionManagement',
    meta: {
      title: '组织权限管理',
      icon: 'form'
    },
    children: [{
      path: 'OrganizationManage',
      name: 'OrganizationManage',
      component: function component() {
        return import('@/views2/HRManagement/PrivilegeManagement/management/organizationManage.vue');
      },
      meta: {
        title: '人力组织管理',
        icon: 'income'
      }
    }, {
      path: 'roleManage',
      name: 'roleManage',
      component: function component() {
        return import('@/views2/HRManagement/PrivilegeManagement/management/roleManage.vue');
      },
      meta: {
        title: '人力角色管理',
        icon: 'example'
      }
    }, {
      path: 'roleAllocation',
      name: 'roleAllocation',
      component: function component() {
        return import('@/views2/HRManagement/PrivilegeManagement/management/roleAllocation.vue');
      },
      meta: {
        title: '人力角色分配',
        icon: 'nested'
      }
    }]
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    title: '我的报表',
    icon: 'example'
  },
  children: [{
    path: '',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '经营类',
      icon: 'form'
    },
    children: [{
      path: 'report/autoTable',
      name: 'autoTable',
      hidden: true,
      component: function component() {
        return import('@/views2/autoTable/index.vue');
      },
      meta: {
        title: '报表模板',
        icon: 'example'
      }
    }, {
      path: 'report/autoReport',
      name: 'AutoReport',
      component: function component() {
        return import('@/views2/autoReport/index.vue');
      },
      meta: {
        title: '自助报表',
        icon: 'example'
      }
    }, {
      path: 'report/showReport',
      name: 'ShowReport',
      hidden: true,
      component: function component() {
        return import('@/views2/autoReport/components/showReport.vue');
      },
      meta: {
        title: '查看报表',
        icon: 'example'
      }
    }, {
      path: 'reportQueries/MonthlyARPU',
      name: 'MonthlyARPU',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '权责ARPU值月报',
        icon: 'bill'
      },
      props: {
        defineId: '18006'
      }
    }, // 个宽经营月报
    {
      path: 'reportQueries/monthlyReport',
      name: 'monthlyReport',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '个宽经营月报',
        icon: 'bill'
      },
      props: {
        defineId: '18007'
      }
    }, // // 账务统计
    // {
    //   path: 'reportQueries/financialTj',
    //   name: 'financialTj',
    //   component: () => import('@/views/rpt/index.vue'),
    //   meta: {
    //     title: '账务统计',
    //     icon: 'bill'
    //   },
    //   props: {
    //     defineId: '13624'
    //   }
    // },
    // 权责统计
    {
      path: 'reportQueries/quanzeTj',
      name: 'quanzeTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '权责统计',
        icon: 'bill'
      },
      props: {
        defineId: '14431'
      }
    }, // 押金余额统计
    {
      path: 'reportQueries/depositTj',
      name: 'depositTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '押金余额统计',
        icon: 'bill',
        auth: '押金余额统计'
      },
      props: {
        defineId: '8025'
      }
    }, // 已用资源统计报表
    {
      path: 'reportQueries/resTj',
      name: 'resTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '已用资源统计',
        icon: 'bill'
      },
      props: {
        defineId: '14856'
      }
    }, // 用户信息统计
    {
      path: 'reportQueries/userinfoTj',
      name: 'userinfoTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '用户信息统计',
        icon: 'bill'
      },
      props: {
        defineId: '13825'
      }
    }, // 已用促销品统计
    {
      path: 'reportQueries/marketTj',
      name: 'marketTj',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '已用促销品统计',
        icon: 'bill'
      },
      props: {
        defineId: '18001'
      }
    }, // 电话登记量预警
    {
      path: 'reportQueries/telephoneRegistration',
      name: 'telephoneRegistration',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '电话登记量预警',
        icon: 'bill'
      },
      props: {
        defineId: '13828'
      }
    }, // JH-用户信息统计
    {
      path: 'reportQueries/JH_userinfoTj',
      name: 'telephoneRegistration',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: 'JH-用户信息统计',
        icon: 'bill'
      },
      props: {
        defineId: '18016'
      }
    }, // JH-用户信息统计
    {
      path: 'reportQueries/JH_quanzeTj',
      name: 'telephoneRegistration',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: 'JH-权责统计',
        icon: 'bill'
      },
      props: {
        defineId: '18017'
      }
    }, // JH-押金余额统计
    {
      path: 'reportQueries/JH_depositTj',
      name: 'telephoneRegistration',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: 'JH-押金余额统计',
        icon: 'bill'
      },
      props: {
        defineId: '18018'
      }
    }, // JH-月权责ARPU值战区城市月报
    {
      path: 'reportQueries/JH_cityYB',
      name: 'telephoneRegistration',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: 'JH-月权责ARPU值战区城市月报',
        icon: 'bill'
      },
      props: {
        defineId: '18019'
      }
    }]
  }, {
    path: 'reportQueries',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '财务类',
      icon: 'form'
    },
    children: [{
      path: 'payedAccountAging',
      name: 'payedAccountAging',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '应付账款账龄分析',
        icon: 'bill'
      },
      props: {
        businessType: 'staffOrgTree',
        defineId: '18014'
      }
    }, {
      path: 'budgetQuery',
      name: 'budgetQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '预算汇总',
        icon: 'bill'
      },
      props: {
        businessType: 'staffOrgTree',
        defineId: '18011'
      }
    }, {
      path: 'executeQuery',
      name: 'executeQuery',
      component: function component() {
        return import('@/views/rpt/index.vue');
      },
      meta: {
        title: '执行汇总',
        icon: 'bill'
      },
      props: {
        businessType: 'staffOrgTree',
        defineId: '18013'
      }
    }]
  }, {
    path: '',
    component: NoPageRoute,
    name: '',
    meta: {
      title: '人力类',
      icon: 'form'
    },
    children: []
  }]
}];
/**
 * 当前路由是否有菜单 且菜单有权限
 * */

function menuHasAuth(routePath, menuAuths, loginAuths) {
  var menu = menuAuths.find(function (menuAuth) {
    return "".concat(menuAuth.pc) === routePath || "/".concat(menuAuth.pc) === routePath || "//".concat(menuAuth.pc) === routePath || "///".concat(menuAuth.pc) === routePath;
  }); // 是否有菜单

  if (menu) {
    if (typeof menu.auth === 'undefined' || menu.auth === '' || loginAuths.includes(menu.auth)) {
      // 菜单是否有权限
      return true;
    }
  }

  return false;
}

function useRoutes(menuArr, currentRoutes, viewRouterList, loginAuths, fatherRoutePath) {
  currentRoutes.forEach(function (route) {
    return useRoute(menuArr, route, [], loginAuths, fatherRoutePath);
  });
}
/**
 * 是否在用的路由
 * */


function useRoute(menuArr, currentRoute, viewRouterList, loginAuths) {
  var fatherRoutePath = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var routePath = fatherRoutePath === '' ? currentRoute.path || '' : "".concat(fatherRoutePath, "/").concat(currentRoute.path);

  if (currentRoute.children && currentRoute.children.length > 0) {
    viewRouterList.push(currentRoute); // 有子路由的 不需要权限限制

    useRoutes(menuArr, currentRoute.children, loginAuths, routePath);
  }

  menuArr.forEach(function (menu) {
    if (menuHasAuth(routePath, menu, loginAuths)) {
      viewRouterList.push(currentRoute);
    }
  });
}

function filerRoute(currentRoute, viewRouterList, menuArr, authArr) {
  var fatherRoutePath = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var routePath = fatherRoutePath === '' ? currentRoute.path || '' : "".concat(fatherRoutePath, "/").concat(currentRoute.path);

  if (currentRoute.children && currentRoute.children.length > 0) {
    viewRouterList.push(currentRoute); // 有子路由的 不需要权限限制

    currentRoute.children = filerRouteList(currentRoute.children, menuArr, authArr, routePath);
  } else {
    // 无子路由
    if (menuHasAuth(routePath, menuArr, authArr)) {
      viewRouterList.push(currentRoute);
    }
  }
}

function filerRouteList(currentRouteList, menuArr, authArr, routePath) {
  var viewRouterList = [];
  currentRouteList.forEach(function (currentRoute) {
    filerRoute(currentRoute, viewRouterList, menuArr, authArr, routePath);
  });
  return viewRouterList;
}

var hiddenRouteMaxSize = 5; // 阈值

/**
 * 递归隐藏
 * */

function hideFatherMenu(viewRouter, fatherViewRouter, level) {
  var viewRouterList = viewRouter.children;

  if (typeof viewRouterList === 'undefined' || viewRouterList.length === 0) {
    return;
  }

  if (level > 0) {
    // 0不隐藏
    // 子路由小于阈值，隐藏子菜单
    var viewRouterListWithOutHidden = viewRouterList.filter(function (ele) {
      return ele.hidden !== true;
    });
    var viewRouterListLen = viewRouterListWithOutHidden.length;

    if (viewRouterListLen <= hiddenRouteMaxSize) {
      viewRouterList.forEach(function (view) {
        var fatherPath = viewRouter.path.length > 0 ? "".concat(viewRouter.path, "/") : '';
        view.path = "".concat(fatherPath).concat(view.path);
        fatherViewRouter.children.push(view);
      });
      viewRouter.children = [];
    }
  }

  if (viewRouterList) {
    var _level = level + 1;

    hideFatherMenus(viewRouterList, viewRouter, _level);
  }
}
/**
 * 根据阈值隐藏二级菜单
 * */


function hideFatherMenus(viewRouterList, fatherViewRouter, level) {
  viewRouterList.forEach(function (ele) {
    return hideFatherMenu(ele, fatherViewRouter, level);
  });
  return viewRouterList;
}
/**
 * 获取路由信息
 * */


export function getRouterListWithAuth(menuArr, authArr) {
  var viewRouterList = hideFatherMenus(filerRouteList(routerList, menuArr, authArr), undefined, 0);
  return viewRouterList;
}
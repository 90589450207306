import "core-js/modules/es6.array.find";
import { Message } from 'element-ui';
import { changeMoney } from '@/utils/index.js';
var state = {
  packageInfo: null,
  submitData: null,
  createOrderData: null,
  callbackData: {},
  certList: []
};
var mutations = {
  SET_PACKAGEINFO: function SET_PACKAGEINFO(state, packageInfo) {
    state.packageInfo = packageInfo;
  },
  RESET_PACKAGEINFO: function RESET_PACKAGEINFO(state) {
    state.packageInfo = null;
  },
  SET_PACKAGETIME: function SET_PACKAGETIME(state, _ref) {
    var startTime = _ref.startTime,
        endTime = _ref.endTime;
    state.packageInfo.startTime = startTime;
    state.packageInfo.endTime = endTime;
  },
  SUBMIT_DATA: function SUBMIT_DATA(state, submitData) {
    state.submitData = submitData;
  },
  CREATE_ORDER_DATA: function CREATE_ORDER_DATA(state, createOrderData) {
    state.createOrderData = createOrderData;
  },
  SET_CERTLIST: function SET_CERTLIST(state, certList) {
    state.certList = certList;
  },
  FORMAT_FOR: function FORMAT_FOR(state) {
    var submitData = state.submitData;
    var res = state.createOrderData;

    if (JSON.stringify(submitData) !== '{}' && res) {
      var loginInfo = JSON.parse(localStorage.getItem('logininfo'));
      var useMoney = submitData.orderUserForm.orderOfferFormList[0].orderBusiPriceFormList.map(function (ele) {
        return "".concat(ele.priceName, ": ").concat(changeMoney(ele.priceValue));
      }).join('<br />');
      var Promotion = submitData.orderMarketingFormList.map(function (ele) {
        return "".concat(ele.resCodeName, " ( ").concat(ele.marketingNum, " )");
      }).join('<br />');
      var certType = state.certList.find(function (ele) {
        return ele.codeValue * 1 === res.custCertType * 1;
      });
      state.callbackData = {
        businessName: res.businessName,
        brand: [{
          label: '业务单号',
          text: res.customerOrderId
        }, {
          label: '受理日期',
          text: res.createDate
        }],
        info: [{
          label: '用户姓名',
          text: res.custName
        }, {
          label: '联系电话',
          text: submitData.contPhone
        }, {
          label: '手机号',
          text: submitData.orderUserForm.userTel
        }, {
          label: '证件类型',
          text: certType.codeName
        }, {
          label: '证件号码',
          text: res.custCertCode
        }, {
          label: '接入地址',
          text: submitData.orderUserForm.addrExt
        }, {
          label: '套餐名称',
          text: submitData.orderUserForm.orderOfferFormList[0].offerName
        }, {
          label: '服务号码',
          text: submitData.orderUserForm.billId
        }, {
          label: '总金额',
          text: changeMoney(submitData.shouldMoney)
        }, {
          label: '费用信息',
          text: useMoney
        }, {
          label: '促销品',
          text: !!Promotion ? Promotion : '无'
        }, {
          label: '备注',
          text: submitData.remarks ? submitData.remarks : '无'
        }],
        operator: [{
          label: '操作员',
          text: loginInfo.operatorName
        }, {
          label: '受理组织',
          text: loginInfo.org.district
        }]
      };
    } else {
      Message('受理单获取数据有误');
    }
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
import "core-js/modules/es6.function.name";

/**
 *@param request 之前的请求方法
 * @param options 配置 {}
 * @param params 参数
 * */
var requestCollection = {}; // 当前发送的请求集合

var requestCollectionState = {}; // 当前发送的请求状态的集合

/**
  * 获取唯一标识
  * */

function getUniqueId(requestName, options, param) {
  var uniqueConf = options.uniqueConf;

  if (typeof uniqueConf !== 'undefined') {
    // 如果存在唯一约束的配置
    var pagePath = uniqueConf.pagePath; // 取唯一约束的页面

    return "".concat(requestName, "-").concat(pagePath);
  }

  return requestName;
}
/**
  * 正常请求基础
  * */


function requestProBase(uniqueId, request, options) {
  for (var _len = arguments.length, params = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    params[_key - 3] = arguments[_key];
  }

  var requestProState = requestCollectionState[uniqueId];
  return new Promise(function (resolve, reject) {
    if (typeof requestProState === 'undefined') {
      requestCollectionState[uniqueId] = 'ing';
      request.apply(void 0, params).then(function (response) {
        resolve(response);
      }).catch(function (e) {
        reject(e);
      }).finally(function () {
        delete requestCollectionState[uniqueId];
      });
    } else {
      reject('请求正在响应，请勿重复发起');
      throw new Error('请求正在响应，请勿重复发起');
    }
  });
}
/**
  * 添加防抖后
  * */


function requestProDebounce(uniqueId, request, options) {
  for (var _len2 = arguments.length, params = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
    params[_key2 - 3] = arguments[_key2];
  }

  var requestProFun = requestCollection[uniqueId];
  var debounceTime = options.debounceTime;

  if (requestProFun && typeof requestProFun === 'function') {
    requestProFun();
  }

  return new Promise(function (resolve, reject) {
    var timer = setTimeout(function () {
      clearTimeout(timer);
      requestProBase.apply(void 0, [uniqueId, request, options].concat(params)).then(function (res) {
        resolve(res);
      }).catch(function (e) {
        reject(e);
      });
    }, debounceTime);

    requestCollection[uniqueId] = function () {
      clearTimeout(timer);
      reject(new Error('触发防抖，上次请求被取消'));
    };
  });
}

export default function requestPro(request, options) {
  for (var _len3 = arguments.length, params = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
    params[_key3 - 2] = arguments[_key3];
  }

  var uniqueId = getUniqueId(request.name, options, params);
  var debounceTime = options.debounceTime;

  if (typeof debounceTime === 'number') {
    return requestProDebounce.apply(void 0, [uniqueId, request, options].concat(params));
  } else {
    return requestProBase.apply(void 0, [uniqueId, request, options].concat(params));
  }
}
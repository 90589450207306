var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "searchMenu" },
        [
          _c(
            "el-select",
            {
              staticClass: "select-style",
              attrs: {
                size: "mini",
                clearable: "",
                filterable: "",
                placeholder: "请选择菜单"
              },
              on: {
                change: _vm.changeClick,
                "visible-change": _vm.visibleChange
              },
              model: {
                value: _vm.inputMenu,
                callback: function($$v) {
                  _vm.inputMenu = $$v
                },
                expression: "inputMenu"
              }
            },
            _vm._l(_vm.menuList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.meta.title, value: item.fullPath }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                router: "",
                "active-text-color": _vm.variables.menuActiveText,
                "background-color": _vm.variables.menuBg,
                collapse: _vm.isCollapse,
                "collapse-transition": false,
                "default-active": _vm.activeMenu,
                "text-color": _vm.variables.menuText,
                "unique-opened": true,
                mode: "vertical"
              },
              on: { open: _vm.menuOpen }
            },
            _vm._l(_vm.routes, function(route, index) {
              return _c("sidebar-item", {
                key: route.path + index,
                attrs: { item: route }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from "./request"; // 引入axios的封装方法

var baseApi = 'webApi/bop-purchase/v1/';
var baseBusinessApi = 'webApi/bop-purchase-business/v1/';
var baseTimerApi = 'webApi/bop-purchase-timer/v1/'; // 供应商模块API
// 供应商字典
//

export var querySupplyDict = function querySupplyDict(dictType) {
  return request('get', "".concat(baseApi, "supply/enums/").concat(dictType));
}; // 分页查询供应商

export var querySupplyApi = function querySupplyApi(query) {
  return request('get', "".concat(baseApi, "supply"), query);
}; // 保存供应商

export var createSupplyApi = function createSupplyApi(params) {
  return request('post', "".concat(baseApi, "supply"), params);
}; // 供应商详情

export var querySupplyDetailApi = function querySupplyDetailApi(id) {
  return request('get', "".concat(baseApi, "supply/").concat(id));
}; // 根据ID移除供应商

export var deleteSupplyApiById = function deleteSupplyApiById(id, params) {
  return request('delete', "".concat(baseApi, "supply/").concat(id), undefined, 20 * 1000, params);
}; // 根据ID修改供应商

export var updateSupplyApi = function updateSupplyApi(params) {
  var id = params.id;
  return request('put', "".concat(baseApi, "supply/").concat(id), params);
}; // 移除供应商

export var deleteSupplyApi = function deleteSupplyApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseApi, "supply/batch"), undefined, 20 * 1000, params);
}; // 商品模块API
// 商品分页查询

export var queryGoodsApi = function queryGoodsApi(params) {
  return request('get', "".concat(baseApi, "item"), params);
}; // 根据ID移除商品

export var deleteGoodsByIdApi = function deleteGoodsByIdApi(id, params) {
  return request('delete', "".concat(baseApi, "item/").concat(id), undefined, 20 * 1000, params);
}; // 添加商品

export var createGoods = function createGoods(params) {
  return request('post', "".concat(baseApi, "item"), params);
}; // 根据ID更新商品

export var updateGoodsByIdApi = function updateGoodsByIdApi(params) {
  var id = params.id;
  return request('put', "".concat(baseApi, "item/").concat(id), params);
}; // 批量删除商品

export var deleteGoodsApi = function deleteGoodsApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseApi, "item/batch"), undefined, 20 * 1000, params);
}; // 查询商品分类

export var queryGoodsCategoriesApi = function queryGoodsCategoriesApi() {
  return request('get', "".concat(baseApi, "item/categories"));
}; // 根据分类ID查询商品分类

export var queryGoodsCategoryByIdApi = function queryGoodsCategoryByIdApi(categoryId) {
  return request('get', "".concat(baseApi, "item/category/").concat(categoryId));
}; // 查询商品费用类型

export var queryGoodsFeeCategoriesApi = function queryGoodsFeeCategoriesApi() {
  return request('get', "".concat(baseApi, "item/feeCategories"));
}; // 根据ID查询商品费用类型

export var queryGoodsFeeCategoryByIdApi = function queryGoodsFeeCategoryByIdApi(feeCategoryId) {
  return request('get', "".concat(baseApi, "item/feeCategory/").concat(feeCategoryId));
}; // 获取商品详情

export var queryGoodsDetailApi = function queryGoodsDetailApi(id) {
  return request('get', "".concat(baseApi, "item/").concat(id));
}; // 查询商品

export var queryGoodsListApi = function queryGoodsListApi(query) {
  return request('get', "".concat(baseApi, "item"), query);
}; // 采购模块 /v1/purchase/enum/{type} Purchase
// 采购字典

export var queryPurchaseDictApi = function queryPurchaseDictApi(type) {
  return request('get', "".concat(baseApi, "purchase/enum/").concat(type));
}; // 采购单分页查询

export var queryPurchaseListApi = function queryPurchaseListApi(params) {
  return request('get', "".concat(baseApi, "purchase/apply"), params);
}; // 查询采购申请单详情

export var queryPurchaseInfoByIdApi = function queryPurchaseInfoByIdApi(id) {
  return request('get', "".concat(baseApi, "purchase/apply/").concat(id));
}; // 创建采购申请

export var createPurchaseApi = function createPurchaseApi(query) {
  return request('post', "".concat(baseApi, "purchase/apply"), query);
}; // 更新采购申请

export var updatePurchaseApi = function updatePurchaseApi(params) {
  var id = params.id;
  return request('put', "".concat(baseApi, "purchase/apply/").concat(id), params);
}; // 移除采购单

export var deletePurchaseApi = function deletePurchaseApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseApi, "purchase/apply/batch"), undefined, 20 * 1000, params);
}; // 查询采购单的明细的合同

export var queryPurchaseContractApi = function queryPurchaseContractApi(id, itemId) {
  return request('get', "".concat(baseApi, "purchase/apply/").concat(id, "/contract/").concat(itemId));
}; // 查询采购单的明细的分页

export var queryPurchaseApi = function queryPurchaseApi(query) {
  return request('get', "".concat(baseApi, "purchase/apply/item"), query);
}; // 合同模块
// 合同字典查询

export var queryContractEnumApi = function queryContractEnumApi(type) {
  return request('get', "".concat(baseApi, "contract/enum/").concat(type));
}; // 合同明细查询

export var queryContractDetailApi = function queryContractDetailApi(params) {
  return request('get', "".concat(baseApi, "contract/item"), params);
}; // 合同分页

export var queryContractListApi = function queryContractListApi(params) {
  return request('get', "".concat(baseApi, "contract"), params);
};
export var deleteContractByIdApi = function deleteContractByIdApi(id) {
  var params = {
    contractId: id
  };
  return request('delete', "".concat(baseApi, "contract/").concat(id), undefined, 20 * 1000, params);
}; // 移除合同

export var deleteContractApi = function deleteContractApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseApi, "contract/batch"), undefined, 20 * 1000, params);
}; // 查看合同详情

export var queryContractInfoByIdApi = function queryContractInfoByIdApi(id) {
  return request('get', "".concat(baseApi, "contract/").concat(id));
}; // 创建合同

export var createContractApi = function createContractApi(params) {
  return request('post', "".concat(baseApi, "contract"), params);
}; // 修改合同

export var updateContractApi = function updateContractApi(params) {
  var id = params.id;
  return request('put', "".concat(baseApi, "contract/").concat(id), params);
}; // 合同应付分页

export var queryContractPaymentApi = function queryContractPaymentApi(contractId, params) {
  return request('get', "".concat(baseApi, "contract/").concat(contractId, "/payment"), params);
}; // 合同应付 无分页

export var queryContractPaymentAllApi = function queryContractPaymentAllApi(contractId, params) {
  return request('get', "".concat(baseApi, "contract/").concat(contractId, "/payment"), params);
}; // 合同应付分页

export var queryContractPaymentListApi = function queryContractPaymentListApi(params) {
  return request('get', "".concat(baseApi, "contract/payment"), params);
}; // 供应商字典类型

export var getSupplyCodeTypeApi = function getSupplyCodeTypeApi(type) {
  return request('get', "".concat(baseApi, "supply/enums/").concat(type));
}; // 合同字典类型

export var getContractCodeTypeApi = function getContractCodeTypeApi(type) {
  return request('get', "".concat(baseApi, "contract/enum/").concat(type));
}; // 查询合同费用类型

export var queryContractFeeCategoriesApi = function queryContractFeeCategoriesApi() {
  return request('get', "".concat(baseApi, "contract/feeCategories"));
}; // 查询合同分类

export var queryContractCategoriesApi = function queryContractCategoriesApi() {
  return request('get', "".concat(baseApi, "contract/categories"));
}; // 采购字典类型

export var getPurchaseCodeTypeApi = function getPurchaseCodeTypeApi(type) {
  return request('get', "".concat(baseApi, "purchase/enum/").concat(type));
}; // 采购单提交审批

export var purchaseSubmitApi = function purchaseSubmitApi(id) {
  return request('put', "".concat(baseApi, "purchase/apply/").concat(id, "/submit"), {});
}; // 合同单提交审批

export var contractSubmitApi = function contractSubmitApi(id) {
  return request('put', "".concat(baseApi, "contract/").concat(id, "/submit"), {});
}; // 预算模块/v1/budget/enum/{type}
// 预算字典

export var queryBudgetEnumsApi = function queryBudgetEnumsApi(type) {
  return request('get', "".concat(baseApi, "budget/enum/").concat(type));
}; // 新增预算

export var createBudgetApi = function createBudgetApi(params) {
  return request('post', "".concat(baseApi, "budget"), params);
}; // 更新预算

export var updateBudgetApi = function updateBudgetApi(params) {
  var id = params.budgetDto.id;
  return request('put', "".concat(baseApi, "budget/").concat(id), params);
}; // 删除预算

export var deleteBudgetByIdApi = function deleteBudgetByIdApi(id) {
  var params = {
    id: id
  };
  return request('delete', "".concat(baseApi, "budget/").concat(id), undefined, 20 * 1000, params);
}; // 删除预算

export var deleteBudgetApi = function deleteBudgetApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseApi, "budget/batch"), undefined, 20 * 1000, params);
}; // 提交预算申请

export var submitBudgetApi = function submitBudgetApi(id) {
  return request('put', "".concat(baseApi, "budget/").concat(id, "/submit"), {
    budgetId: id
  });
}; // 预算分页查询

export var queryBudgetApi = function queryBudgetApi(params) {
  return request('get', "".concat(baseApi, "budget"), params);
}; // 查询预算详情

export var queryBudgetInfoApi = function queryBudgetInfoApi(id) {
  return request('get', "".concat(baseApi, "budget/").concat(id));
}; // 预算明细分页查询

export var queryBudgetItemApi = function queryBudgetItemApi(params) {
  return request('get', "".concat(baseApi, "budget/item"), params);
}; // 预算明细分页查询

export var queryBudgetItemByIdApi = function queryBudgetItemByIdApi(id) {
  return request('get', "".concat(baseApi, "budget/item/").concat(id));
}; // 根据应付ID获取预算明细

export var queryBudgetItemByPaymentIdApi = function queryBudgetItemByPaymentIdApi(params) {
  return request('get', "".concat(baseApi, "budget/item/ext"), params);
}; // ////#############业务模块（合同执行）
// 业务费用类型

export var getBusinessFeeCategoryApi = function getBusinessFeeCategoryApi(id) {
  return request('get', "".concat(baseApi, "contract/feeCategory"));
}; // 业务关联合同

export var getBusinessContractApi = function getBusinessContractApi(feeCode, params) {
  return request('get', "".concat(baseApi, "contract/feeCategory/").concat(feeCode, "/contract"), params);
}; // 分页查询业务

export var queryBusinessPageApi = function queryBusinessPageApi(params) {
  return request('get', "".concat(baseBusinessApi, "business/purchaseContract"), params);
}; // 创建业务

export var createBusinessApi = function createBusinessApi(params) {
  return request('post', "".concat(baseBusinessApi, "business"), params);
}; // 查询批量支出申请单列表

export var queryBusinessListApi = function queryBusinessListApi(params) {
  return request('get', "".concat(baseBusinessApi, "business"), params);
}; // 创建批量支出申请单

export var createBatchPaymentApplyApi = function createBatchPaymentApplyApi(params) {
  return request('post', "".concat(baseBusinessApi, "business"), params);
}; // 删除批量支出申请单

export var deleteBatchPaymentApplyApi = function deleteBatchPaymentApplyApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseBusinessApi, "business/batch"), undefined, 20 * 1000, params);
}; // 删除单个支出申请单

export var deletePaymentApplyApi = function deletePaymentApplyApi(id) {
  return request('delete', "".concat(baseBusinessApi, "business/").concat(id));
}; // 更新支出申请单

export var updatePaymentApplyApi = function updatePaymentApplyApi(id, params) {
  return request('put', "".concat(baseBusinessApi, "business/").concat(id), params);
}; // 提交批量支出申请单流程

export var submitBatchPaymentApplyApi = function submitBatchPaymentApplyApi(ids) {
  var params = {
    ids: ids
  };
  return request('put', "".concat(baseBusinessApi, "business/batch"), params);
}; // 发起支出申请单流程

export var submitPaymentApplyApi = function submitPaymentApplyApi(id) {
  return request('put', "".concat(baseBusinessApi, "business/").concat(id, "/submit"), {});
}; // 查询支出申请单详情

export var getPaymentApplyDetailApi = function getPaymentApplyDetailApi(id) {
  return request('get', "".concat(baseBusinessApi, "business/").concat(id), {});
}; // 查询业务详情

export var queryBusinessInfoApi = function queryBusinessInfoApi(id) {
  return request('get', "".concat(baseBusinessApi, "business/").concat(id));
}; // 更新业务

export var updateBusinessApi = function updateBusinessApi(params) {
  var id = params.id;
  return request('put', "".concat(baseBusinessApi, "business/").concat(id), params);
}; // 提交业务

export var submitBusinessApi = function submitBusinessApi(id) {
  return request('put', "".concat(baseBusinessApi, "business/").concat(id, "/submit"), {});
}; // 创建差旅费报销单

export var createTravelBusinessApi = function createTravelBusinessApi(params) {
  return request('post', "".concat(baseTimerApi, "business/travelExpense"), params);
}; // 应付拆分

export var splitBusinessApi = function splitBusinessApi(params) {
  return request('put', "".concat(baseTimerApi, "business/split"), params);
}; // #######################支出模块
// 合同字典查询

export var queryPaymentEnumApi = function queryPaymentEnumApi(type) {
  return request('get', "".concat(baseApi, "payment/enum/").concat(type));
}; // 分页查询支出

export var queryPaymentPageApi = function queryPaymentPageApi(params) {
  return request('get', "".concat(baseApi, "payment"), params);
}; // 创建支出

export var createPaymentApi = function createPaymentApi(params) {
  return request('post', "".concat(baseApi, "payment"), params);
}; // 查询支出详情

export var queryPaymentInfoApi = function queryPaymentInfoApi(id) {
  return request('get', "".concat(baseApi, "payment/").concat(id));
};
export var downloadTravelExpensePdfApi = function downloadTravelExpensePdfApi(id) {
  return request('img', "".concat(baseTimerApi, "business/pdf/").concat(id, "?action=pdfDownLoad"));
};
export var downloadPaymentInfoPdfApi = function downloadPaymentInfoPdfApi(id) {
  return request('img', "".concat(baseTimerApi, "payment/").concat(id, "?action=pdfDownLoad"));
}; // 批量支出审批下载支出申请的pdf接口

export var downloadBusinessInfoPdfApi = function downloadBusinessInfoPdfApi(id) {
  return request('img', "".concat(baseTimerApi, "business/pdf/").concat(id, "?action=pdfDownLoad"));
};
export var submitPaymentApi = function submitPaymentApi(id) {
  return request('put', "".concat(baseApi, "payment/").concat(id, "/submit"), {});
}; // 新增收款记录

export var addPaymentRecordApi = function addPaymentRecordApi(params) {
  var id = params.id;
  return request('post', "".concat(baseApi, "payment/").concat(id, "/record"), params);
}; // 根据id查询收款记录详情-主键id

export var getPaymentRecordApi = function getPaymentRecordApi(id) {
  return request('get', "".concat(baseApi, "payment/record/").concat(id));
}; // 新增支出日志

export var addPaymentCommentApi = function addPaymentCommentApi(params) {
  var id = params.id;
  return request('post', "".concat(baseApi, "payment/").concat(id, "/comment"), params);
}; // 删除支出申请

export var deletePaymentApi = function deletePaymentApi(ids) {
  var params = {
    ids: ids
  };
  return request('delete', "".concat(baseApi, "payment/batch"), undefined, 20 * 1000, params);
}; // 根据合同应付ID获取付款信息

export var getPaymentByContractPaymentIdApi = function getPaymentByContractPaymentIdApi(params) {
  return request('get', "".concat(baseApi, "payment/record/info"), params);
};
export var addPaymentRecordCompletedApi = function addPaymentRecordCompletedApi(params) {
  var paymentId = params.id;
  return request('post', "".concat(baseApi, "payment/").concat(paymentId, "/recordCompleted"), params);
};
export var updatePaymentRecordCompletedApi = function updatePaymentRecordCompletedApi(params) {
  var paymentId = params.id;
  return request('put', "".concat(baseApi, "payment/").concat(paymentId, "/recordCompleted"), params);
}; // 日志类
//

export var addComment4ApplyApi = function addComment4ApplyApi(businessKey, params) {
  return request('post', "".concat(baseApi, "purchase/apply/").concat(businessKey, "/comment"), params);
};
export var addComment4ContractApi = function addComment4ContractApi(businessKey, params) {
  return request('post', "".concat(baseApi, "contract/").concat(businessKey, "/comment"), params);
};
export var addComment4BudgetApi = function addComment4BudgetApi(businessKey, params) {
  return request('post', "".concat(baseApi, "budget/").concat(businessKey, "/comment"), params);
};
export var addComment4PaymentApi = function addComment4PaymentApi(businessKey, params) {
  return request('post', "".concat(baseApi, "payment/").concat(businessKey, "/comment"), params);
};
export var addComment4BusinessApi = function addComment4BusinessApi(businessKey, params) {
  return request('post', "".concat(baseBusinessApi, "business/").concat(businessKey, "/comment"), params);
};
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
export var projectTheme = function projectTheme() {
  if (['ckSubcontracting', 'ckSubcontracting_local'].includes(process.env.VUE_APP_NODE_ENV)) {
    require("@/styles/indexck.scss"); // .js 扩展名可忽略

  } else if (['jhSubcontracting', 'jhSubcontracting_local'].includes(process.env.VUE_APP_NODE_ENV)) {
    require("@/styles/indexjh.scss"); // .js 扩展名可忽略

  } else {
    require("@/styles/index.scss"); // .js 扩展名可忽略

  }
};
export var returnTheme = function returnTheme() {
  if (['ckSubcontracting', 'ckSubcontracting_local'].includes(process.env.VUE_APP_NODE_ENV)) {
    return require('@/styles/ck/variables.scss');
  } else if (['jhSubcontracting', 'jhSubcontracting_local'].includes(process.env.VUE_APP_NODE_ENV)) {
    return require('@/styles/jh/variables.scss');
  } else {
    return require('@/styles/index/variables.scss');
  }
};
import "core-js/modules/es6.regexp.to-string";
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import uMengMixins from '@/mixins/uMengMixins';
export default {
  name: 'App',
  mixins: [uMengMixins()],
  computed: _objectSpread({}, mapState({
    keepAlive: function keepAlive(state) {
      return state.keepAlive.keepAlive.toString();
    }
  }))
};
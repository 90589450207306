import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import isNumber from 'xe-utils/isNumber';
import toValueString from 'xe-utils/toValueString';
import toDateString from 'xe-utils/toDateString';
/**
 * @author 籍龙波
 * 字符串工具类
 * */

export function isEmpty(str) {
  return isUndefined(str) || isNull(str) || str.length === 0;
}
export function isNotEmpty(str) {
  return !isEmpty(str);
}
export function isNull(str) {
  return str === null;
}
export function isNotNull(str) {
  return !isNull(str);
}
export function isUndefined(str) {
  return typeof str === 'undefined';
}
export function isNotUndefined(str) {
  return !isUndefined(str);
}
/**
 *格式化金额
 * */

export function avueFormatterMoneyUtil(_ref) {
  var row = _ref.row,
      value = _ref.value,
      label = _ref.label,
      column = _ref.column;
  return formatterMoneyUtil(value);
}
export function vxeFormatterMoneyUtil(_ref2) {
  var cellValue = _ref2.cellValue;
  return formatterMoneyUtil(cellValue);
}
export function formatterMoneyUtil(num) {
  if (typeof num === 'undefined' || num === null) {
    return '--';
  }

  if (isNumber(num)) {
    return toValueString((num / 100).toFixed(2));
  } else {
    throw new Error('分转元入参必须为number类型');
  }
}
/**
 *格式化日期
 * */

export function vxeFormatterDateUtil(_ref3) {
  var cellValue = _ref3.cellValue;
  return formatterDateUtil(cellValue);
}
export function formatterDateUtil(value) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd HH:mm:ss';
  return toDateString(value, format);
}
export function priceTransform(price) {
  if (isNaN(price)) {
    return;
  }

  var priceCapital = priceTransformFn(price);
  return priceCapital === '整' ? '零元整' : priceCapital;
}
var priceNum = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
export function priceTransformFn(price) {
  // ""：为了能够识别1000亿
  var priceBigType = ['元', '万', '亿', ''];
  var priceInt = parseInt(price);
  var typeCount = parseInt(String(priceInt).length / 4);
  var remainderCount = String(priceInt).length % 4;

  if (typeCount > priceBigType.length - 2 && remainderCount > 0) {
    return '最大单位为' + priceBigType[priceBigType.length - 2];
  }

  var strLast = '整'; // 角分

  if (priceInt !== price) {
    var priceLastType = ['角', '分'];
    var lastPrice = /\.(\d{1,2})/.exec(price)[1];
    var lastStrArr = lastPrice.split('');
    var temStrArr = lastStrArr.map(function (item, index) {
      if (+item) {
        return priceNum[+item] + priceLastType[index];
      } else {
        return '';
      }
    });
    strLast = temStrArr.join('');
  }

  var strFirst = '';
  var count = 0;

  while (count <= typeCount) {
    var sindex = remainderCount + (count - 1) * 4 < 0 ? 0 : remainderCount + (count - 1) * 4;
    var eindex = remainderCount + count * 4;
    var temNum = String(priceInt).substring(sindex, eindex);
    strFirst += getPriceStr(temNum) ? getPriceStr(temNum) + priceBigType[typeCount - count] : '';
    count++;
  }

  return strFirst + strLast;
}

function getPriceStr(price) {
  var priceMinType = ['', '拾', '佰', '仟'];
  var temFirstStrArr = price.split('');
  temFirstStrArr.reverse();
  var temStrArr = temFirstStrArr.map(function (item, index) {
    if (+item) {
      return priceNum[+item] + priceMinType[index];
    } else {
      return priceNum[+item];
    }
  });
  temStrArr.reverse();
  return temStrArr.join('').replace(/零+/g, '零').replace(/零$/, '');
}
/**
 * 接口URL文档
 * 刘松
 */
import request from "./request"; // 引入axios的封装方法
// 完成任务

var baseApi = '/toolApi/v1/tools/';
export var getTimestamps = function getTimestamps(params) {
  return request('get', "".concat(baseApi, "/timestamps"), params);
};
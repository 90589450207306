var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.login.token;
  },
  loginInfo: function loginInfo(state) {
    return state.login.loginInfo;
  },
  getCurrentPath: function getCurrentPath(state) {
    return state.permission.currentPath;
  }
};
export default getters;
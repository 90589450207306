var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Toolbar" },
    [
      _c(
        "el-popover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loginState,
              expression: "!loginState"
            }
          ],
          attrs: { placement: "bottom", width: "500", trigger: "manual" },
          model: {
            value: _vm.loginPopover,
            callback: function($$v) {
              _vm.loginPopover = $$v
            },
            expression: "loginPopover"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "ToolbarForm",
              attrs: {
                model: _vm.loginForm,
                "label-width": "80px",
                size: "mini"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "登录类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.loginForm.loginType,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "loginType", $$v)
                        },
                        expression: "loginForm.loginType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "天润", value: "天润" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "企业编号" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: _vm.customerSelect,
                        "allow-create": _vm.customerSelect,
                        placeholder: _vm.selectEmptyPlaceholder
                      },
                      on: { change: _vm.identifierChange },
                      model: {
                        value: _vm.loginForm.identifier,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "identifier", $$v)
                        },
                        expression: "loginForm.identifier"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "测试", value: "ckzb-gd" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "长鸿", value: "pengboshi" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "自定义企业编号",
                          value: "customerIdentifier"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "坐席号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.loginForm.cno,
                      callback: function($$v) {
                        _vm.$set(_vm.loginForm, "cno", $$v)
                      },
                      expression: "loginForm.cno"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.loginForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "绑定电话" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.loginForm.bindType,
                                callback: function($$v) {
                                  _vm.$set(_vm.loginForm, "bindType", $$v)
                                },
                                expression: "loginForm.bindType"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "普通电话" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "分机" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 3, label: "软电话" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 15 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入号码", clearable: "" },
                            model: {
                              value: _vm.loginForm.bindTel,
                              callback: function($$v) {
                                _vm.$set(_vm.loginForm, "bindTel", $$v)
                              },
                              expression: "loginForm.bindTel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "初始状态" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.loginForm.loginStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "loginStatus", $$v)
                        },
                        expression: "loginForm.loginStatus"
                      }
                    },
                    [_vm._v("空闲")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.loginForm.loginStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "loginStatus", $$v)
                        },
                        expression: "loginForm.loginStatus"
                      }
                    },
                    [_vm._v("忙碌")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "loginBtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "success", size: "mini" },
                      on: { click: _vm.login }
                    },
                    [_vm._v("登录")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "mini", type: "warning" },
                      on: {
                        click: function($event) {
                          _vm.loginPopover = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { slot: "reference", plain: "", size: "mini" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.loginPopoverSwitch($event)
                }
              },
              slot: "reference"
            },
            [_vm._v("登录电话条")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loginState,
              expression: "loginState"
            }
          ],
          staticClass: "loginBtnBox"
        },
        [
          _vm.showComingCallNo
            ? _c(
                "div",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                size: "mini",
                                disabled: "",
                                value: _vm.comingCallNoWithCity
                              }
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("来电:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:error",
                                      value: _vm.onCopyError,
                                      expression: "onCopyError",
                                      arg: "error"
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.comingCallNo,
                                      expression: "comingCallNo",
                                      arg: "copy"
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: _vm.onCopySuccess,
                                      expression: "onCopySuccess",
                                      arg: "success"
                                    }
                                  ],
                                  attrs: { slot: "append", size: "mini" },
                                  slot: "append"
                                },
                                [_vm._v("复制")]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                size: "mini",
                                disabled: "",
                                value: _vm.calledNo
                              }
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("被叫:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:error",
                                      value: _vm.onCopyError,
                                      expression: "onCopyError",
                                      arg: "error"
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.calledNo,
                                      expression: "calledNo",
                                      arg: "copy"
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: _vm.onCopySuccess,
                                      expression: "onCopySuccess",
                                      arg: "success"
                                    }
                                  ],
                                  attrs: { slot: "append", size: "mini" },
                                  slot: "append"
                                },
                                [_vm._v("复制")]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "busyState" }, [
            _c("div", { staticClass: "stateSpan" }, [
              _vm.stopState === 2
                ? _c("span", { staticClass: "busy" })
                : _vm._e(),
              _vm._v(" "),
              _vm.stopState === 1
                ? _c("span", { staticClass: "ACW" })
                : _vm._e(),
              _vm._v(" "),
              _vm.stopState === 0
                ? _c("span", { staticClass: "notBusy" })
                : _vm._e(),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.curState))])
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("\n        " + _vm._s(_vm.setTime()) + "\n      ")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-content" },
            [
              _c("el-switch", {
                attrs: { "active-text": "WC" },
                model: {
                  value: _vm.WCState,
                  callback: function($$v) {
                    _vm.WCState = $$v
                  },
                  expression: "WCState"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBusy,
                      expression: "isBusy"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.busyState()
                    }
                  }
                },
                [_vm._v("置忙")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isIdle,
                      expression: "isIdle"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.idleState()
                    }
                  }
                },
                [_vm._v("置闲")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCallBtn && !_vm.isCall,
                      expression: "isCallBtn&&!isCall"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "warning" },
                  on: { click: _vm.previewOutcall }
                },
                [_vm._v("外呼取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHangUp,
                      expression: "isHangUp"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "danger" },
                  on: { click: _vm.investigation }
                },
                [_vm._v("挂断")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCallHangUp,
                      expression: "isCallHangUp"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "danger" },
                  on: { click: _vm.hangup }
                },
                [_vm._v("外呼挂断")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHoldBtn && _vm.isHold,
                      expression: "isHoldBtn && isHold"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "info" },
                  on: {
                    click: function($event) {
                      return _vm.holdState(true)
                    }
                  }
                },
                [_vm._v("保持")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHoldBtn && !_vm.isHold,
                      expression: "isHoldBtn && !isHold"
                    }
                  ],
                  attrs: { plain: "", size: "mini", type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.holdState(false)
                    }
                  }
                },
                [_vm._v("保持接回")]
              ),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.istransfer,
                      expression: "istransfer"
                    }
                  ],
                  attrs: {
                    placement: "bottom",
                    width: "250",
                    trigger: "manual"
                  },
                  model: {
                    value: _vm.transferPopover,
                    callback: function($$v) {
                      _vm.transferPopover = $$v
                    },
                    expression: "transferPopover"
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "transferForm",
                      staticClass: "ToolbarForm",
                      attrs: {
                        model: _vm.transferForm,
                        "label-width": "80px",
                        size: "mini"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "转接类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", size: "mini" },
                              model: {
                                value: _vm.transferForm.objectType,
                                callback: function($$v) {
                                  _vm.$set(_vm.transferForm, "objectType", $$v)
                                },
                                expression: "transferForm.objectType"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "电话号码", value: "0" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "座席号", value: "1" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "分机号", value: "2" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "语音导航节点", value: "3" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "语音导航id", value: "4" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "转接号码" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.transferForm.transferObject,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transferForm,
                                  "transferObject",
                                  $$v
                                )
                              },
                              expression: "transferForm.transferObject"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "mini", type: "success" },
                          on: { click: _vm.transfer }
                        },
                        [_vm._v("\n            确认转接\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        plain: "",
                        size: "mini",
                        type: "warning"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.transferPopoverSwitch($event)
                        }
                      },
                      slot: "reference"
                    },
                    [_vm._v("转接")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    width: "250",
                    trigger: "manual"
                  },
                  model: {
                    value: _vm.previewOutcallPopover,
                    callback: function($$v) {
                      _vm.previewOutcallPopover = $$v
                    },
                    expression: "previewOutcallPopover"
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "previewOutcallForm",
                      staticClass: "ToolbarForm",
                      attrs: {
                        model: _vm.previewOutcallForm,
                        "label-width": "80px",
                        size: "mini"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "外呼号码" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.previewOutcallForm.tel,
                              callback: function($$v) {
                                _vm.$set(_vm.previewOutcallForm, "tel", $$v)
                              },
                              expression: "previewOutcallForm.tel"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户侧外显号" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.previewOutcallForm.obClid,
                              callback: function($$v) {
                                _vm.$set(_vm.previewOutcallForm, "obClid", $$v)
                              },
                              expression: "previewOutcallForm.obClid"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "mini", type: "success" },
                          on: { click: _vm.previewOutcall }
                        },
                        [_vm._v("\n            确认外呼\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        plain: "",
                        size: "mini",
                        type: "info"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.previewOutcallPopoverSwitch($event)
                        }
                      },
                      slot: "reference"
                    },
                    [_vm._v("外呼")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "danger" },
                  on: { click: _vm.logout }
                },
                [_vm._v("登出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "info" },
                  on: { click: _vm.changeBindTel }
                },
                [_vm._v("修改绑定电话")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改绑定电话",
            visible: _vm.chBindTelDiaFormVisible,
            "custom-class": "chBindTelDiaFormVisible",
            center: "",
            modal: false,
            "show-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.chBindTelDiaFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "chBindTelDiaForm",
              staticClass: "ToolbarForm",
              attrs: { size: "mini" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "绑定电话" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-dropdown",
                            {
                              attrs: {
                                size: "mini",
                                "split-button": "",
                                type: "success",
                                trigger: "click"
                              },
                              on: { command: _vm.handleCommandBindType }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bindTypeStr) +
                                  "\n              "
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 1 } },
                                    [_vm._v("普通电话")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 2 } },
                                    [_vm._v("分机")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 3 } },
                                    [_vm._v("软电话")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入号码", clearable: "" },
                    model: {
                      value: _vm.loginForm.bindTel,
                      callback: function($$v) {
                        _vm.$set(_vm.loginForm, "bindTel", $$v)
                      },
                      expression: "loginForm.bindTel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", type: "success", size: "mini" },
                  on: { click: _vm.changeBindTelSubmit }
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "warning" },
                  on: {
                    click: function($event) {
                      _vm.chBindTelDiaFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
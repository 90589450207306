var state = {
  customerForm: undefined,
  // 客户数据
  activeInfoForm: undefined,
  // 活动列表
  customerCrowdDto: undefined // 选择的特征种类

};
var mutations = {
  CUSTOMER_FORM: function CUSTOMER_FORM(state, val) {
    state.customerForm = val;
  },
  ACTIVE_INFO_FORM: function ACTIVE_INFO_FORM(state, val) {
    state.activeInfoForm = val;
  },
  CUSTOMER_CROWD_DOT: function CUSTOMER_CROWD_DOT(state, val) {
    state.customerCrowdDto = val;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
import { Notification } from 'element-ui';
import { setStorage } from '@/utils/auth.js';
import { COMING_CALL_INFO_CHANNEL } from '@/constant/eventBusChannel';
export default (function (type, _this) {
  var listener = {
    status: function status(token) {
      var action = token.action;
      var code = token.code;
      var stateName = token.statusMonitorValue;
      _this.curState = stateName;
      _this.callbackbindType = token.bindType ? token.bindType : _this.callbackbindType;

      _this.startTimer();

      switch (action) {
        case 'offline':
          // 离线
          _this.resetBtn();

          _this.loginState = false; // 删除来电信息

          _this.$bus.$emit(COMING_CALL_INFO_CHANNEL, {
            action: 'delete'
          });

          setStorage('toolbar', 0);
          break;

        case 'calling':
          // 被呼叫中, 响铃中
          _this.resetBtn();

          _this.stopState = 2; // 按钮变红

          _this.isReject = true; // 拒接按钮控制

          _this.isRing = true; // 接听按钮

          _this.isAnswer = true; // 显示接听

          break;

        case 'ringingAgentOb':
          // 外呼振铃
          _this.resetBtn(); // 重置按钮装填


          _this.resetPop(); // 关闭弹框, 外呼成功


          _this.isCall = true; // 取消外呼, 外呼按钮控制

          _this.stopState = 2; // 按钮变红

          break;

        case 'busyAgentOb':
          // 外呼通话中
          _this.resetBtn();

          _this.isCallHangUp = true; // 外呼挂断按钮

          _this.isHoldBtn = true; // 保持按钮总控制

          _this.isHold = true; // 保持按钮

          _this.istransfer = true; // 转接按钮

          _this.stopState = 2; // 按钮变红

          break;

        case 'busyIb':
          // 呼入通话
          _this.resetBtn();

          _this.isHangUp = true; // 挂断按钮

          _this.isHoldBtn = true; // 保持按钮总控制

          _this.isHold = true; // 保持按钮

          _this.isHangUp = true; // 挂断

          _this.istransfer = true; // 转接按钮

          _this.stopState = 2; // 按钮变红

          break;

        case 'ACW':
          // 整理
          _this.resetBtn();

          _this.investigation(); // 进行满意度调查


          _this.resetPop(); // 关闭弹框, 外呼成功


          _this.isIdle = true; // 显示置闲

          _this.isAnswer = true; // 显示接听

          _this.stopState = 1; // 按钮变黄

          setStorage('ACW', 1);
          break;

        case 'hold':
          // 保持
          _this.resetBtn();

          _this.isHangUp = true; // 挂断

          _this.isHoldBtn = true; // 保持按钮总控制

          _this.istransfer = true; // 转接按钮

          _this.stopState = 12; // 按钮变红

          break;

        case 'unhold':
          // 保持
          _this.resetBtn();

          _this.isHangUp = true; // 挂断按钮

          _this.isHoldBtn = true; // 保持按钮总控制

          _this.isHold = true; // 保持按钮

          _this.isHangUp = true; // 挂断

          _this.istransfer = true; // 转接按钮

          _this.stopState = 2; // 按钮变红

          break;

        default:
          switch (code) {
            case 'IDLE':
              // 空闲
              _this.resetBtn();

              _this.loginState = true; // 登录状态

              _this.isRing = true; // 接听按钮

              _this.isBusy = true; // 置忙显示

              _this.isCallBtn = true; // 显示外呼所有按钮

              _this.isCall = true; // 显示外呼

              _this.isAnswer = true; // 显示接听

              _this.stopState = 0; // 按钮变绿

              _this.WCState = false; // WC置闲

              setStorage('toolbar', 1);
              setStorage('ACW', 0); // 删除来电信息

              _this.$bus.$emit(COMING_CALL_INFO_CHANNEL, {
                action: 'delete'
              });

              break;

            case 'PAUSE':
              _this.resetBtn();

              _this.loginState = true; // 登录状态

              _this.stopState = 2; // 按钮变红

              _this.isIdle = true; // 显示置闲

              setStorage('ACW', 0);
              break;

            default:
              break;
          }

          break;
      }
    },
    sip_registered: function sip_registered() {
      Notification({
        type: 'success',
        message: '软电话注册成功'
      });
    },
    ringing: function ringing(token) {
      if (token.callType === 1 && _this.loginState === true) {
        var obj;

        var alertType = function alertType(type) {
          if (type === 1) {
            obj = {
              confirmButtonText: '拒接',
              callback: function callback(action, instance) {
                if (action === 'confirm') {
                  _this.refuse(); // 拒接

                }
              }
            };
            return _this.$alert;
          } else {
            obj = {
              confirmButtonText: '接听',
              cancelButtonText: '拒接',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              callback: function callback(action, instance) {
                if (action === 'confirm') {
                  _this.answer(); // 接听

                } else {
                  _this.refuse(); // 拒接

                }
              }
            };
            return _this.$confirm;
          }
        }; // 来电响铃


        alertType(_this.callbackbindType)("".concat(token.customerProvince || '', "/").concat(token.customerCity || '', "\u6765\u7535\u4E86, \u6765\u7535\u53F7\u4E3A").concat(token.customerNumber, ";\u88AB\u53EB\u53F7\u7801\u4E3A").concat(token.hotline), '来电了', obj);
        var comingCallInfo = {
          action: 'insert',
          comingCallNo: token.customerNumber,
          calledNo: token.hotline,
          // 被叫号码(营销小号)
          serialId: token.uuid,
          //  流水号
          customerProvince: token.customerProvince,
          customerCity: token.customerCity
        };

        _this.$bus.$emit(COMING_CALL_INFO_CHANNEL, comingCallInfo);

        _this.$router.push({
          path: '/callcenter/workOrderAcceptance',
          query: {
            phone: token.customerNumber,
            calledNo: token.hotline,
            // 被叫号码(营销小号)
            serialId: token.uuid //  流水号

          }
        });
      } else if (token.callType === 4) {
        // 呼出
        Notification({
          message: '呼出中, 电话号为' + token.customerNumber,
          type: 'info'
        });
      }
    },
    kickout: function kickout() {
      // 来电响铃
      Notification({
        message: '您被踢下线了',
        type: 'warning'
      });
      _this.loginState = false; // 删除来电信息

      _this.$bus.$emit(COMING_CALL_INFO_CHANNEL, {
        action: 'delete'
      });
    },
    preview_outcall_ringing: function preview_outcall_ringing() {
      // 外呼响铃
      Notification({
        message: '外呼响铃中',
        type: 'info'
      });
    },
    preview_outcall_bridge: function preview_outcall_bridge() {
      // 外呼接听
      Notification({
        message: '外呼接听了',
        type: 'success'
      });
    }
  };
  return listener[type];
});
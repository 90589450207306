/**
 * 按钮防暴力重复点击处理 自定义指令
 */
import Vue from 'vue'; // 在vue上挂载一个指量 preventReClick

Vue.directive('preventReClick', {
  inserted: function inserted(el, binding, vNode) {
    el.addEventListener('click', function (e) {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(function () {
          el.disabled = false;
        }, binding.value || 3000); // binding.value可以自行设置。如果设置了则跟着设置的时间走
        // 例如：v-preventReClick='500'
      } else {
        // disabled为true时，阻止默认的@click事件
        e.preventDefault();
        e.stopPropagation();
      }
    }, false); // 最后一个参数上，addEventListener(event, function, useCapture);
    // 默认值为 false, 即冒泡传递(适用于el-button); 当值为 true 时, 事件使用捕获传递(适用于普通html标签)。
    // 所以，暂时不兼容普通标签的点击事件
  }
});
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon'; // svg component
// register globally

Vue.component('svg-icon', SvgIcon);

var req = require.context('./svg', false, /\.svg$/);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

requireAll(req);
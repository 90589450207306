import { Notification } from 'element-ui';
export default (function (type, _this) {
  var callback = {
    login: function login(result) {
      _this.loginPopover = false;

      _this.$ls.set('clinkLoginState', 1);

      code0('登录', result);
    },
    logout: function logout(result) {
      _this.$ls.set('clinkLoginState', 0);

      _this.$ls.remove('clinkLoginForm');

      code0('登出', result);

      if (result.code === 0) {
        _this.$parent.loginOutExecute();
      }
    },
    pause: function pause(result) {
      code0('置忙', result);
    },
    unpause: function unpause(result) {
      code0('置闲', result);
    },
    hold: function hold(result) {
      code0('保持', result);
    },
    refuse: function refuse(result) {
      code0('拒接', result);
    },
    unhold: function unhold(result) {},
    sip_link: function sip_link(result) {
      code0('接听', result);
    },
    sip_unlink: function sip_unlink(result) {
      _this.investigation(); // 挂断后, 自动进行满意度调查

    },
    preview_outcall: function preview_outcall(result) {
      // 外呼
      _this.isCall = true;
    },
    preview_outcall_cancel: function preview_outcall_cancel(result) {},
    change_bind_tel: function change_bind_tel(result) {
      // 修改绑定电话
      _this.chBindTelDiaFormVisible = false;
      code0('修改绑定电话', result);
    }
  };
  return callback[type];
});

var code0 = function code0(name, result) {
  if (result.code === 0) {
    Notification({
      message: name + '成功',
      type: 'success'
    });
  } else {
    Notification({
      message: result.msg,
      type: 'error'
    });
  }
};
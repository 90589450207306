import { getToken, setToken as _setToken, removeToken, getLoginInfo, setLoginInfo, removeLoginInfo, getLoginRole, getLoginMenus, removeReToken, removeTime, setReToken, getApiTime, setApiTime, removeApiTime } from '@/utils/auth';
import { getTimestamps } from '@/api/apiToolUrl.js';
import { resetRouter } from '@/router'; // import axios from 'axios';

var timer = null;
var state = {
  token: getToken(),
  loginInfo: getLoginInfo(),
  role: getLoginRole(),
  menu: getLoginMenus()
}; // console.log('getLoginMenus()==', getLoginMenus());

var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_INfO: function SET_INfO(state, loginInfo) {
    state.loginInfo = loginInfo;
  },
  SET_ROLE: function SET_ROLE(state, role) {
    state.role = role.map(function (item) {
      return item;
    });
  },
  SET_MENU: function SET_MENU(state, menu) {
    state.menu = menu.map(function (item) {
      return item;
    });
  }
};
var actions = {
  login: function login(_ref, info) {
    var commit = _ref.commit,
        dispatch = _ref.dispatch;
    return new Promise(function (resolve, reject) {
      if (info) {
        setLoginInfo(info);
        commit('SET_INfO', info);
        commit('SET_ROLE', info.role);
        commit('SET_MENU', info.menus);
        dispatch('apiTime');
        resolve();
      }
    });
  },
  apiTime: function apiTime(_ref2) {
    var dispatch = _ref2.dispatch;

    if (getApiTime()) {
      if (timer) {
        return getApiTime();
      }

      timer = setInterval(function () {
        setApiTime(getApiTime() + 1000);
      }, 1000);
    } else {
      getTimestamps().then(function (res) {
        setApiTime(res * 1000);
        dispatch('apiTime');
      });
    }
  },
  setToken: function setToken(_ref3, info) {
    var commit = _ref3.commit;

    _setToken(info.access_token);

    setReToken(info.refresh_token);
    commit('SET_TOKEN', info.access_token); // axios.interceptors.request.use(config => {
    //   config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    //   console.log(config, ' <== config');
    //   return config;
    // });
  },
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state;
    commit('SET_TOKEN', '');
    removeToken();
    removeReToken();
    removeLoginInfo();
    removeTime();
    removeApiTime();
    resetRouter();
    return Promise.resolve('登出成功');
  },
  // get user info
  getInfo: function getInfo(_ref5) {
    var commit = _ref5.commit,
        state = _ref5.state;
    return new Promise(function (resolve, reject) {
      resolve('data');
    });
  },
  // remove token
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
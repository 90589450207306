import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.find";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import { returnTheme } from '@/utils/projectTheme.js';
import pageAuth from '@/pageAuth';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      inputMenu: ''
    };
  },
  methods: {
    menuOpen: function menuOpen(index, indexPath) {},
    findChildren: function findChildren(arr) {
      var menu = []; // 默认不需要权限控制就可以展示的菜单

      var defaultMenu = ['callcenter/todoListsNew', 'callcenter/isProcessed', 'callcenter/helpDoc'];

      var func = function func(arr) {
        var filterData = arr.filter(function (item) {
          if (item.children && item.children.length) {
            item.children.forEach(function (data) {
              // 当前路由的fatherPath
              data['fatherPath'] = item.fullPath ? item.fullPath : item.path;
              data['fullPath'] = data.fatherPath === '/' ? data.fatherPath + data.path : data.fatherPath + '/' + data.path;
            });
            func(item.children);
          } else if ((item.hidden === undefined || item.hidden === false) && item.meta && item.meta.title && // 不可以使用pageAuth中的auth与item.meta.title进行判断，有的权限名称和菜单名不是一一对应的
          pageAuth.find(function (child) {
            return child.pc && '/' + child.pc === item.fullPath && child.auth;
          }) || defaultMenu.includes(item.path)) {
            item['id'] = Math.random().toString(36).substr(2) + Date.now();
            return item;
          }
        });
        menu = menu.concat(filterData);
      };

      func(arr);
      return menu;
    },
    changeClick: function changeClick($e) {
      var _this = this;

      this.$nextTick(function () {
        _this.$router.push($e[0] === '/' ? $e : '/' + $e);
      });
    },
    visibleChange: function visibleChange(visible) {
      if (visible) {
        this.inputMenu = '';
      }
    }
  },
  computed: _objectSpread({}, mapGetters(['sidebar']), {
    routes: function routes() {
      // return this.$router.options.routes;
      var data;

      if (global.antRouter) {
        data = this.$router.options.routes.concat(global.antRouter);
      } else {
        data = this.$router.options.routes;
      }

      return data.filter(function (ele) {
        return typeof ele.meta !== 'undefined';
      });
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return returnTheme();
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    menuList: function menuList() {
      var data;

      if (global.antRouter) {
        data = this.$router.options.routes.concat(global.antRouter);
      } else {
        data = this.$router.options.routes;
      }

      var menu = this.findChildren(data);
      return menu;
    }
  })
};
var state = {
  routeFromName: '' // 来自于的路由Name

};
var mutations = {
  /**
   * 设置来自于的路由名称
   * */
  setRouteFromName: function setRouteFromName(state, routeFromName) {
    state.routeFromName = routeFromName;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};
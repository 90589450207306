import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUsername(str) {
  var valid_map = ['13112341234', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
/**
 * У������ ��6-20λ������������ֺ���ĸ
 * @param pass
 * @returns {boolean}
 */

export function checkPassword(pass) {
  var pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
  return pattern.test(pass);
}
export function validIDCard(rule, value, callback) {
  // 加权因子
  var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 校验码

  var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
  var code = value + '';
  var last = value[17]; // 最后一位

  var seventeen = code.substring(0, 17); // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确

  var arr = seventeen.split('');
  var len = arr.length;
  var num = 0;

  for (var i = 0; i < len; i++) {
    num = num + arr[i] * weight_factor[i];
  } // 获取余数


  var resisue = num % 11;
  var last_no = check_code[resisue];
  var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/; // 判断格式是否正确

  var format = idcard_patter.test(value); // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码

  var valid = !!(last === last_no && format);
  valid ? callback() : callback(new Error('身份证件号格式错误'));
}
export function validPassPortCard(rule, value, callback) {
  // 护照
  // var reg = /^[E|D|S|P|G]{1}[0-9]{8}$/;
  // reg.test(value) ? callback() : callback(new Error('身份证件号格式错误'));
  value.length === 9 ? callback() : callback(new Error('身份证件号格式错误'));
} // 手机号校验

export var telValidate = function telValidate(rule, value, callback) {
  var regs = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;

  if (!regs.test(value)) {
    return callback(new Error('请正确填写手机号'));
  } else {
    callback();
  }
}; // 邮箱校验

export var mailValidate = function mailValidate(rule, value, callback) {
  var regs = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  if (!regs.test(value)) {
    return callback(new Error('请正确填写邮箱'));
  } else {
    callback();
  }
};
export var salaryStandardValidator = function salaryStandardValidator(value, callback, data) {
  // console.log(value, 'value', data);
  if (!data) {
    return callback(new Error("\u8BF7\u5148\u9009\u62E9\u5DE5\u8D44\u804C\u7EA7\uFF0C\u5C97\u4F4D\u3001\u804C\u52A1\u3001\u5DE5\u8D44\u804C\u7EA7\u4E3A\u5173\u8054\u5173\u7CFB"));
  } else if (data === 'default') {
    callback();
  } else if (JSON.stringify(data) !== {}) {
    if (value < data.minValue || value > data.maxValue || !Number(value)) {
      return callback(new Error("\u8BF7\u586B\u5199".concat(data.minValue, "~").concat(data.maxValue, "\u8303\u56F4\u5185\u7684\u6574\u6570")));
    } else {
      callback();
    }
  }
}; // 金额校验

export var amountValidate = function amountValidate(rule, value, callback) {
  var reg = /^([0-9][0-9]*)+(\.[0-9]{1,2})?$/;
  reg.test(value) ? callback() : callback(new Error('只能填写整数或者保留两位小数的有效数字'));
}; // 特殊字符

export var specialStrValidate = function specialStrValidate(value) {
  var patrn = /[`~!@#$%^&()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&（）——\-+={}|《》？：“”【】、；‘'，。、]/im;

  if (!patrn.test(value)) {
    // 如果包含特殊字符返回true
    return true;
  }

  return false;
};
var state = {
  oldBillId: '',
  pwdSubmitDisabled: null,
  sendMesTime: null,
  timer: null
};
var mutations = {
  OLD_BILL_ID: function OLD_BILL_ID(state, val) {
    state.oldBillId = val;
  },
  PWD_SUBMIT_DISABLED: function PWD_SUBMIT_DISABLED(state, val) {
    state.pwdSubmitDisabled = val;
  },
  SEND_MES_TIME: function SEND_MES_TIME(state, val) {
    state.sendMesTime = val;
  },
  TIMER: function TIMER(state, val) {
    state.timer = val;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};
import Vue from 'vue';
import { ClinkAgent } from '@/utils/clink-client.js';
import { getStorage } from '@/utils/auth.js';
Vue.directive('toolbarunhold', {
  inserted: function inserted(el) {
    // 点击按钮
    el.onclick = function (e) {
      var ACW = getStorage('ACW');

      if (ACW === '1') {
        ClinkAgent.unpause();
      } else {}
    };
  }
});
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout'; // import Home from './../views/home/index.vue';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [// {
//   path: '/',
//   name: 'home',
//   component: Home,
//   meta: {
//     keepAlive: true // 需要缓存
//   }
// },
{
  path: '/login',
  name: '登录',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  name: '未找到页面',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, // {
//   path: '/fault', // 故障
//   name: 'fault',
//   component: () => import('@/views/fault/index.vue')
// },
{
  path: '/',
  component: Layout,
  redirect: '/index',
  hidden: true,
  children: [{
    path: '',
    name: 'index',
    component: function component() {
      return import('@/views2/index/index');
    },
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
} // 404 page must be placed at the end !!!
// { path: '*', redirect: '/404', hidden: true }
];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // export function resetRouter() {
//   const newRouter = createRouter();
//   router.matcher = newRouter.matcher; // reset router
// }

export function resetRouter() {
  location.reload(); // reset router
}
export default router;
import request from "./request"; // 引入axios的封装方法
// 查找商机信息

export var findOpportunities = function findOpportunities(params) {
  return request('get', "/teleApi/v1/opportunity/find-opportunities/", params);
}; // 查找单个商机信息

export var findOneOpportunity = function findOneOpportunity(params) {
  return request('get', "/teleApi/v1/opportunity/find-one-opportunity", params);
}; // 查询活动实例

export var activityInstance = function activityInstance(params) {
  return request('get', "/teleApi/v1/activity-instance", params);
}; // 删除某一活动实例详细信息

export var DelActivityInstance = function DelActivityInstance(id) {
  return request('delete', "/teleApi/v1/activity-instance/".concat(id));
}; // 更改商机信息

export var modifyOpportunity = function modifyOpportunity(params) {
  return request('post', "/teleApi/v1/opportunity/modify-opportunity", params);
}; // 跟进记录保存

export var followDescribe = function followDescribe(params) {
  return request('post', "/teleApi/v1/opportunity/record-follow-describe", params);
}; // 根据字典Code查找字典数据

export var getDictionary = function getDictionary(params) {
  return request('get', "/teleApi/v1/dictionary", params);
}; // 查找活动中商机的片区和服务站列表

export var getOrganizes = function getOrganizes(id, params) {
  return request('get', "/teleApi/v1/activity-instance/".concat(id, "/organizes"), params);
}; // 根据字典Code查找字典数据（多级时用“-”拼接在一起）

export var getDictree = function getDictree(params) {
  return request('get', "/teleApi/v1/dictionary/tree", params);
}; // 电销模块下的活动列表

export var getActiveList = function getActiveList(params) {
  return request('get', "/teleApi/v1/activity-definition", params);
};
export var activityDefinitionDetail = function activityDefinitionDetail(id, params) {
  return request('get', "/teleApi/v1/activity-definition/".concat(id), params);
};
export var deleteActiveDefinition = function deleteActiveDefinition(id, params) {
  return request('delete', "/teleApi/v1/activity-definition/".concat(id), params);
}; // 查询某一活动详细信息

export var activityInstanceDetail = function activityInstanceDetail(id, params) {
  return request('get', "/teleApi/v1/activity-instance/".concat(id), params);
};
/** ------------------------ 查询客户群特征 -------------------------------- */

export var characteristicSpecificatio = function characteristicSpecificatio(params) {
  return request('get', "/teleApi/v1/characteristic-specification", params);
};
/** ----------------------- 统计客户群户数-------------------------------------- */

export var calculateCount = function calculateCount(params) {
  return request('post', "/teleApi/v1/customer-crowd/calculate-count", params);
};
/** ----------------------- 查询客户群信息-------------------------------------- */

export var customerCrowd = function customerCrowd(params) {
  return request('get', "/teleApi/v1/customer-crowd", params);
};
/** ----------------------- 创建客户群-------------------------------------- */

export var createCustomerCrowd = function createCustomerCrowd(params) {
  return request('post', "/teleApi/v1/customer-crowd", params);
};
/** ----------------------- 创建活动模板-------------------------------------- */

export var activityDefinition = function activityDefinition(params) {
  return request('post', "/teleApi/v1/activity-definition", params);
};
/** ----------------------- 查询客户群信息id-------------------------------------- */

export var customerCrowdID = function customerCrowdID(params) {
  return request('get', "/teleApi/v1/customer-crowd/".concat(params));
}; // 商机回收

export var retrieveOpportunity = function retrieveOpportunity(data) {
  return request('post', "/teleApi/v1/opportunity/retrieve", data);
}; // 商机转移

export var transferOpportunity = function transferOpportunity(data) {
  return request('post', "/teleApi/v1/opportunity/transfer", data);
}; // 商机分配

export var distribution = function distribution(params) {
  return request('post', "/teleApi/v1/opportunity/distribution", params);
}; // 根据成员Code查找多级字典名称

export var treeName = function treeName(params) {
  return request('get', "/teleApi/v1/dictionary/tree-name", params);
}; // 查询片区和服务站

export var organizes = function organizes(id, params) {
  return request('get', "/teleApi/v1/activity-instance/".concat(id, "/organizes"), params);
};
export var exportExcel = function exportExcel(id, params) {
  return request('img', "/teleApi/v1/activity-instance/".concat(id, "/excel"), params);
}; // 预约提醒-数量

export var reminderCount = function reminderCount(params) {
  return request('get', '/teleApi/v1/opportunity/reminder/count', params);
}; // 预约提醒-列表

export var reminderList = function reminderList(params) {
  return request('get', '/teleApi/v1/opportunity/reminder', params);
};
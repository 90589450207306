import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
// 工单类型对照
// 0:故障|1:质量|2:非单|4:投诉|5:新开|6:续费|7:退费|8:业务|9:咨询|10:移机|11:增值|12:电销续费   |3:通用(废弃)
import { refundProcessNew, distributionWorkOrderProcess } from "../constant/flow/processDef";
export default function (orderKey) {
  var arr = [{
    name: '故障',
    orderId: 0,
    urlName: 'malfunction',
    iconBackground: 'background:#458B74',
    taskName: 'faultOrderProcess',
    selectTreeAxios: 'DRPENG.WOS.PERSONALBROADBAND.DHGZ.FAULTTYPE',
    partOrderAxios: 'DRPENG.CH.FAULT.PROCESSTYPE'
  }, {
    name: '质量',
    orderId: 1,
    urlName: 'quality',
    iconBackground: 'background:#999900',
    taskName: 'qualityOrderProcess',
    selectTreeAxios: 'DRPENG.WOS.PERSONALBROADBAND.DHZL.FAULTTYPE',
    partOrderAxios: 'DRPENG.CH.QUALITY.PROCESSTYPE'
  }, {
    name: '非单',
    orderId: 2,
    urlName: '',
    iconBackground: 'background:#9AC0CD',
    taskName: 'massOrderProcess',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '投诉',
    orderId: 4,
    urlName: 'complaints',
    taskName: 'complaintProcess',
    iconBackground: 'background:#BC8F8F',
    selectTreeAxios: 'DRPENG.CH.COMPLAINT.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.COMPLAINT.PROCESSTYPE'
  }, {
    name: '新开',
    orderId: 5,
    urlName: 'openNew',
    taskName: 'newProdMain',
    iconBackground: 'background:#FF6A6A',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '续费',
    orderId: 6,
    urlName: 'renewal',
    taskName: 'renewProdMain',
    iconBackground: 'background:#EEB422',
    selectTreeAxios: '',
    partOrderAxios: 'DRPENG.CH.RENEW.SUCCESSRESULT'
  }, {
    name: '退费',
    orderId: 7,
    urlName: 'refund',
    taskName: 'refundProcess',
    iconBackground: 'background:#668B8B',
    selectTreeAxios: 'DRPENG.CH.REFUND.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.REFUND.PROCESSTYPE'
  }, {
    name: '退费',
    orderId: 107,
    urlName: refundProcessNew,
    taskName: refundProcessNew,
    iconBackground: 'background:#668B8B',
    selectTreeAxios: 'DRPENG.CH.REFUND.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.REFUND.PROCESSTYPE'
  }, {
    name: '配送',
    orderId: 108,
    urlName: distributionWorkOrderProcess,
    taskName: distributionWorkOrderProcess,
    iconBackground: 'background:#668B8B',
    selectTreeAxios: 'DRPENG.CH.REFUND.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.REFUND.PROCESSTYPE'
  }, {
    name: '业务',
    orderId: 8,
    urlName: 'business',
    taskName: 'businessProcess',
    iconBackground: 'background:#9F79EE',
    selectTreeAxios: 'DRPENG.CH.BUSINESS.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.BUSINESS.PROCESSTYPE'
  }, {
    name: '咨询',
    orderId: 9,
    urlName: 'advisory',
    taskName: 'consultProcess',
    iconBackground: 'background:#9C9C9C',
    selectTreeAxios: 'DRPENG.CH.CONSULT.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.CONSULT.PROCESSTYPE'
  }, {
    name: '移机',
    orderId: 10,
    urlName: 'relocation',
    taskName: 'shiftProcess',
    iconBackground: 'background:#63B8FF',
    selectTreeAxios: 'DRPENG.CH.SHIFT.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.SHIFT.PROCESSTYPE'
  }, {
    name: '增值',
    orderId: 11,
    urlName: 'appreciation',
    taskName: 'valuesAddedProcess',
    iconBackground: 'background:#00C5CD',
    selectTreeAxios: 'DRPENG.CH.VALUESADDED.BUSITYPE',
    partOrderAxios: 'DRPENG.CH.VALUESADDED.PROCESSTYPE'
  }, {
    name: '电销',
    orderId: 12,
    urlName: 'telemarketingrenewal',
    taskName: 'telemarketingRenewProdMain',
    iconBackground: 'background:#EEB422',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '宣传',
    orderId: 13,
    urlName: 'PublicityWorkOrder',
    taskName: 'PublicityWorkOrder',
    iconBackground: 'background:#A07192',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '安装',
    orderId: 14,
    urlName: 'constructionProcess',
    taskName: 'constructionProcess',
    iconBackground: 'background:#4B0082',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '审核',
    orderId: null,
    urlName: 'examineProcess',
    taskName: 'examineProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '采购申请',
    orderId: null,
    urlName: 'purchaseApplyProcess',
    taskName: 'purchaseApplyProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '预算',
    orderId: null,
    urlName: 'budgetProcess',
    taskName: 'budgetProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '支出',
    orderId: null,
    urlName: 'paymentProcess',
    taskName: 'paymentProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '采购业务',
    orderId: null,
    urlName: 'purchaseBusinessProcess',
    taskName: 'purchaseBusinessProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '采购业务',
    orderId: null,
    urlName: 'purchaseBusinessReimburseProcess',
    taskName: 'purchaseBusinessReimburseProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '采购业务',
    orderId: null,
    urlName: 'purchaseBusinessTravelExpenseProcess',
    taskName: 'purchaseBusinessTravelExpenseProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '合同',
    orderId: null,
    urlName: 'contractProcess',
    taskName: 'contractProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '薪资数据',
    orderId: null,
    urlName: 'kpiProcess',
    taskName: 'kpiProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }, {
    name: '薪资',
    orderId: null,
    urlName: 'staffSalaryProcess',
    taskName: 'staffSalaryProcess',
    iconBackground: 'background:#5F9EA0',
    selectTreeAxios: '',
    partOrderAxios: ''
  }];
  var newkey = null;

  if (orderKey === undefined) {
    return arr;
  } else if (isNaN(Number(orderKey))) {
    newkey = orderKey;
  } else {
    newkey = Number(orderKey);
  }

  var tempOrderType = arr.find(function (fd) {
    var isFind = false;

    for (var key in fd) {
      var ele = fd[key];

      if (ele === newkey) {
        isFind = true;
      }
    }

    return isFind;
  });
  return tempOrderType || {
    name: '其它'
  };
}
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapState } from 'vuex'; // import Breadcrumb from '@/components/Breadcrumb';

import Hamburger from '@/components/Hamburger'; // import { userLogout } from '@/api/apiUrl';
// import Search from '@/components/HeaderSearch';

import Toolbar from '@/components/Toolbar/index.vue';
import orgChangeList from '@/views/authority/orgChange.vue';
import { queryOperatorRoleList } from '@/api/apiUrl';
import { reminderCount } from '@/api/apiTeleUrl';
var nodeEnv = process.env.VUE_APP_NODE_ENV;
export default {
  components: {
    // Breadcrumb,
    Hamburger: Hamburger,
    Toolbar: Toolbar,
    orgChangeList: orgChangeList // Search

  },
  data: function data() {
    return {
      nodeEnv: nodeEnv,
      isShowCount: false,
      count: 0,
      // 预约提醒的数量
      timer: null,
      dialogDisabled: false,
      isLoginOut: false // 是否已经准备登出

    };
  },
  computed: _objectSpread({}, mapState({
    orgName: function orgName(state) {
      return state.login.loginInfo.org.orgName;
    }
  }), {}, mapGetters(['sidebar'])),
  watch: {
    '$route.query.count': function $routeQueryCount(value) {
      if (value !== 'undefined') {
        this.count = value;
      }
    }
  },
  mounted: function mounted() {
    this.$store.dispatch('login/apiTime');
    this.$refs.ToolBarRef.loginState = false;
    this.getRoleList();
  },
  methods: {
    //
    gotoHelpDoc: function gotoHelpDoc() {
      this.$router.push({
        name: 'HelpDoc'
      });
    },
    // 自定义一个interval方法
    interval: function interval() {
      var func = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
      var wait = arguments.length > 1 ? arguments[1] : undefined;

      var interv = function interv() {
        // eslint-disable-next-line no-useless-call
        func.call(null);
        this.timer = setTimeout(interv, wait);
      };

      this.timer = setTimeout(interv, wait);
    },
    // 角色范围，“电销人员”和“电销组员”
    getRoleList: function getRoleList() {
      var _this = this;

      var operatorId = JSON.parse(localStorage.getItem('logininfo')).userId;
      queryOperatorRoleList({
        operatorId: operatorId
      }).then(function (res) {
        _this.isShowCount = res.content.some(function (ele) {
          return ele.roleName === '电销组员' || ele.roleName === '电销人员';
        });

        if (_this.isShowCount) {
          _this.getCount();
        }
      });
    },
    // 点击消息图标
    hAlertMsg: function hAlertMsg() {
      this.getCount();
      this.$router.push('/telemarketing/alertMessage');
    },
    // 查询消息数
    getCount: function getCount() {
      var _this2 = this;

      var memberId = JSON.parse(localStorage.getItem('logininfo')).userId;
      reminderCount({
        memberId: memberId
      }).then(function (res) {
        _this2.count = res.result;
      });
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    logout: function logout() {
      this.isLoginOut = true; // loginState // 未登录:false, 登录成功: true

      if (this.$refs.ToolBarRef.loginState && this.$refs.ToolBarRef.loginState === true) {
        this.$refs.ToolBarRef.logout();
      } else {
        this.loginOutExecute();
      }
    },
    // 执行登出操作
    loginOutExecute: function loginOutExecute() {
      var _this3 = this;

      if (this.isLoginOut === true) {
        this.$ls.set('clinkLoginState', 0);
        this.$ls.remove('clinkLoginForm'); // 点击了退出按钮，并调用了执行退出操作时，退出

        this.$store.dispatch('login/logout').then(function (msg) {
          _this3.$message({
            message: msg,
            type: 'success'
          });

          _this3.$router.push("/login?redirect=".concat(_this3.$route.fullPath));
        });
      }
    },
    orgChange: function orgChange() {
      this.dialogDisabled = true;
    },
    beforeDestroy: function beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
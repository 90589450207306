import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectWithoutProperties from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectWithoutProperties";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import { ClinkAgent } from '@/utils/clink-client.js';
import cbF from '@/utils/callbackFunction.js';
import lnF from '@/utils/listenerFunction.js';
import { COMING_CALL_INFO_CHANNEL } from '@/constant/eventBusChannel';
import { isNotEmpty } from '@/utils/StrUtil';
import { mapGetters, mapMutations } from 'vuex';
import { SAVE_CALL_INFO, DELETE_CALL_INFO, GET_CALL_INFO } from '@/store/modules/comingCallInfo';
export default {
  data: function data() {
    return {
      customerSelect: false,
      // 企业编码是否可自定义
      selectEmptyPlaceholder: '请选择企业编码',
      chBindTelDiaFormVisible: false,
      loginForm: {
        identifier: 'pengboshi',
        // 企业编码  pengboshi
        cno: '',
        // 坐席号
        password: '',
        // 密码
        loginType: '天润',
        // 下拉框选择第三方电话类型
        bindTel: '',
        // 软电话号
        loginStatus: '1',
        // 登录状态，1：空闲、2：忙碌
        bindType: 2,
        // 绑定类型，1：普通电话、2：分机、3：软电话
        chatLimitNumber: 10,
        // 可接待会话数，指定则更新当前设置值
        chatLoginStatus: '1' // 1：空闲 2：置忙，全渠道、在线类型座席必选

      },
      logoutForm: {
        logoutMode: 1,
        removeBinding: 1
      },
      transferForm: {
        // 转接功能
        objectType: '',
        // 转移类型，0：电话号码、1：座席号、2：分机号、3：语音导航节点、4：语音导航id
        transferObject: '' // 转移号码

      },
      busyForm: {
        pauseType: 1 // 类型，1：置忙、2：休息

      },
      holdForm: {
        holdType: 0 // 保持类型，0：通话保持、1：咨询保持、2：转移保持，默认通话保持

      },
      previewOutcallForm: {
        tel: '',
        // 外呼号码
        obClid: '' // 客户侧外显号码组

      },
      callbackbindType: 0,
      loginPopover: false,
      transferPopover: false,
      // 转接弹框
      previewOutcallPopover: false,
      // 转接弹框
      loginState: false,
      // 未登录:false, 登录成功: true
      stopState: 0,
      // 不接听电话状态, 置忙, WC 都是拒接状态2, 整理状态1, 置闲状态0
      isHoldBtn: false,
      // 保持按钮总控制
      isCallBtn: false,
      // 外呼按钮总控制
      isAnswer: false,
      // 接听按钮控制
      isRing: false,
      // 接听按钮
      isBusy: false,
      // 忙碌按钮控制
      isIdle: false,
      // 空闲按钮控制
      isHold: false,
      // 保持按钮: true, 保持接回按钮|初始: false
      isReject: false,
      // 拒接按钮控制
      isCall: false,
      // 取消外呼, 外呼按钮控制
      isHangUp: false,
      // 挂断按钮
      istransfer: false,
      // 转接按钮
      isCallHangUp: false,
      // 外呼挂断
      WCState: false,
      // 上厕所状态
      time: 0,
      // 秒数
      timer: null,
      curState: '空闲'
    };
  },
  computed: _objectSpread({}, mapGetters({
    callInfo: GET_CALL_INFO
  }), {
    comingCallNo: function comingCallNo() {
      return this.callInfo.comingCallNo;
    },
    comingCallNoWithCity: function comingCallNoWithCity() {
      if (this.callInfo.customerProvince && this.callInfo.customerCity) {
        return "".concat(this.comingCallNo, "(").concat(this.callInfo.customerProvince, "/").concat(this.callInfo.customerCity, ")");
      }

      return undefined;
    },
    calledNo: function calledNo() {
      return this.callInfo.calledNo;
    },
    showComingCallNo: function showComingCallNo() {
      return Object.keys(this.callInfo).length > 0;
    },
    bindTypeStr: function bindTypeStr() {
      var tempBindTypeStr = '';

      switch (this.loginForm.bindType) {
        case 1:
          tempBindTypeStr = '普通电话';
          break;

        case 2:
          tempBindTypeStr = '分机';
          break;

        case 3:
          tempBindTypeStr = '软电话';
          break;

        default:
          tempBindTypeStr = '软电话';
          break;
      }

      return tempBindTypeStr;
    }
  }),
  mounted: function mounted() {
    var _this2 = this;

    this.init();
    this.registerComingCallInfo();
    this.windowclick();
    this.$nextTick(function () {
      var clinkLoginState = _this2.$ls.get('clinkLoginState', 0);

      if (clinkLoginState === 1) {
        // 电话条为登录状态，需要后台自动登录电话条
        var clinkLoginForm = _this2.$ls.get('clinkLoginForm', undefined);

        _this2.login(clinkLoginForm);
      }
    }); // 联系用户=>装机

    this.$bus.$on('TEL_TO_INSTALL_EQUIPMENT', function (data) {
      if (_this2.loginState) {
        _this2.$confirm('确认拨打?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.previewOutcallForm.tel = data;

          _this2.previewOutcall();
        }).catch(function () {
          _this2.$message({
            type: 'info',
            message: '已取消拨打'
          });
        }); // 已登录

      } else {
        // 未登录
        _this2.$message('请先登录电话条');
      }
    });
  },
  methods: _objectSpread({
    /** 复制失败 */
    onCopyError: function onCopyError() {
      this.$message({
        message: '复制失败！',
        type: 'error'
      });
    },

    /** 复制成功 */
    onCopySuccess: function onCopySuccess() {
      this.$message({
        message: '复制到剪切板成功！',
        type: 'success'
      });
    }
  }, mapMutations({
    saveCallInfo: SAVE_CALL_INFO
  }), {}, mapMutations({
    deleteCallInfo: DELETE_CALL_INFO
  }), {
    // 注册总线消息  可以通过此渠道添加、移除、修改来电号码信息
    registerComingCallInfo: function registerComingCallInfo() {
      var _this3 = this;

      this.$bus.$on(COMING_CALL_INFO_CHANNEL, function (data) {
        // action 操作信息
        // callInfo 来电信息
        var action = data.action,
            callInfo = _objectWithoutProperties(data, ["action"]);

        if (['insert', 'delete', 'update'].includes(action)) {
          if (action === 'delete') {
            _this3.deleteComingCallInfo();
          } else {
            _this3.saveComingCallInfo(callInfo);
          }
        } else {
          throw new Error("".concat(COMING_CALL_INFO_CHANNEL, ",\u672A\u5B9A\u4E49\u6B64\u64CD\u4F5C\u7C7B\u578B").concat(action));
        }
      });
    },
    // 保存来电信息，如果没有则新增，有则更新
    saveComingCallInfo: function saveComingCallInfo(callInfo) {
      // comingCallNo 主叫号码
      // calledNo 被叫号码(营销小号)
      // serialId  流水号
      var comingCallNo = callInfo.comingCallNo,
          calledNo = callInfo.calledNo,
          serialId = callInfo.serialId;

      if (isNotEmpty(comingCallNo) && isNotEmpty(calledNo) && isNotEmpty(serialId)) {
        this.saveCallInfo(callInfo);
      } else {
        throw new Error("".concat(COMING_CALL_INFO_CHANNEL, ",\u4E3B\u53EB\u53F7\u7801\u3001\u88AB\u53EB\u53F7\u7801\u3001\u6D41\u6C34\u53F7\u4E0D\u53EF\u4E3A\u7A7A"));
      }
    },
    // 删除之前保存的来电信息
    deleteComingCallInfo: function deleteComingCallInfo() {
      this.deleteCallInfo();
    },
    // 选择企业编码发生改变时
    identifierChange: function identifierChange(currentValue) {
      if (currentValue === 'customerIdentifier') {
        this.customerSelect = true;
        this.loginForm.identifier = '';
        this.selectEmptyPlaceholder = '请在此输入自定义的企业编号';
      }
    },
    // 修改绑定号码按钮点击
    changeBindTel: function changeBindTel() {
      this.chBindTelDiaFormVisible = true;
    },
    // 切换电话类型
    handleCommandBindType: function handleCommandBindType(command) {
      this.loginForm.bindType = command;
    },
    // 修改绑定号码确认提交
    changeBindTelSubmit: function changeBindTelSubmit() {
      this.clinkAgentChangeBindTel();
    },
    resetPop: function resetPop() {
      this.loginPopover = false;
      this.transferPopover = false;
      this.previewOutcallPopover = false;
    },
    resetBtn: function resetBtn() {
      this.isHoldBtn = false; // 保持按钮总控制

      this.isCallBtn = false; // 外呼按钮总控制

      this.isAnswer = false; // 接听按钮控制

      this.isRing = false; // 接听按钮

      this.isBusy = false; // 忙碌按钮控制

      this.isIdle = false; // 空闲按钮控制

      this.isHold = false; // 保持按钮: true, 保持接回按钮|初始: false

      this.isReject = false; // 拒接按钮控制

      this.isCall = false; // 取消外呼, 外呼按钮控制

      this.isHangUp = false; // 挂断按钮

      this.istransfer = false; // 转接按钮

      this.isCallHangUp = false; // 外呼挂断
    },
    windowclick: function windowclick() {
      var _this4 = this;

      // 点击其他位置关闭弹框
      window.addEventListener('click', function () {
        _this4.resetPop();
      });
    },
    setTime: function setTime() {
      var s = this.time % 60;
      var m = Math.floor(this.time / 60) % 60;
      var h = Math.floor(this.time / 3600);

      var zero = function zero(num) {
        return String(num).length === 1 ? '0' + num : num;
      };

      return zero(h) + ':' + zero(m) + ':' + zero(s);
    },
    startTimer: function startTimer() {
      var _this5 = this;

      this.time = 0;
      clearInterval(this.timer);
      this.timer = setInterval(function () {
        _this5.time++;
      }, 1000);
    },
    init: function init() {
      var _this = this;

      ClinkAgent.setup({
        sipPhone: true,
        debug: false
      }, function () {
        /* 注册状态事件 */
        ClinkAgent.registerListener(ClinkAgent.EventType.STATUS, lnF('status', _this)); // 当前座席状态

        ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_RINGING, lnF('preview_outcall_ringing', _this)); // 外呼响铃

        ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_BRIDGE, lnF('preview_outcall_bridge', _this)); // 外呼接通
        // 软电话注册成功事件

        ClinkAgent.registerListener(ClinkAgent.EventType.SIP_REGISTERED, lnF('sip_registered', _this)); // 响铃事件

        ClinkAgent.registerListener(ClinkAgent.EventType.RINGING, lnF('ringing', _this));
        ClinkAgent.registerListener(ClinkAgent.EventType.KICKOUT, lnF('kickout', _this));
        /* ---------------------------注册callBack事件 -----------------------*/
        // 注册登录

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGIN, cbF('login', _this)); // 注册登出

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGOUT, cbF('logout', _this)); // 注册置忙

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.PAUSE, cbF('pause', _this)); // 注册置闲

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.UNPAUSE, cbF('unpause', _this)); // 注册保持

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.HOLD, cbF('hold', _this)); // 注册保持接回

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.UNHOLD, cbF('unhold', _this)); // 注册软电话接听

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.SIP_LINK, cbF('sip_link', _this)); // 注册软电话挂断

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.SIP_UNLINK, cbF('sip_unlink', _this)); // 注册拒接

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.REFUSE, cbF('refuse', _this)); // 注册预览外呼

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.PREVIEW_OUTCALL, cbF('preview_outcall', _this)); // 注册外呼取消

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.PREVIEW_OUTCALL_CANCEL, cbF('preview_outcall_cancel', _this)); // 注册修改绑定电话

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.CHANGE_BIND_TEL, cbF('change_bind_tel', _this));
      });
    },
    loginPopoverSwitch: function loginPopoverSwitch() {
      this.loginPopover = true;
    },
    transferPopoverSwitch: function transferPopoverSwitch() {
      this.transferPopover = true;
    },
    previewOutcallPopoverSwitch: function previewOutcallPopoverSwitch() {
      this.previewOutcallPopover = true;
    },
    login: function login(clinkLoginForm4local) {
      var loginForm = clinkLoginForm4local && clinkLoginForm4local.cno ? clinkLoginForm4local : this.loginForm;

      if (loginForm && loginForm.cno && loginForm.cno.length > 0) {
        // 有坐席号，才能登录
        this.$ls.set('clinkLoginForm', loginForm);
        ClinkAgent.login(loginForm);
      }
    },
    logout: function logout() {
      if (!this.isBusy) {
        this.idleState();
      }

      ClinkAgent.logout(this.logoutForm);
    },
    busyState: function busyState(desc) {
      desc ? this.busyForm.pauseDescription = desc : delete this.busyForm.pauseDescription;
      ClinkAgent.pause(this.busyForm);
    },
    idleState: function idleState() {
      ClinkAgent.unpause();
    },
    holdState: function holdState(bool) {
      // 保持
      if (bool) {
        ClinkAgent.hold(this.holdForm);
      } else {
        ClinkAgent.unhold();
      }
    },
    answer: function answer() {
      // 接听
      ClinkAgent.sipLink();
    },
    hangup: function hangup() {
      // 挂断, 满意度调查包括挂断功能, 此挂断仅 用作外呼挂断
      ClinkAgent.sipUnlink();
    },
    refuse: function refuse() {
      // 拒接
      ClinkAgent.refuse();
    },
    transfer: function transfer() {
      // 转接
      ClinkAgent.transfer(this.transferForm);
    },
    investigation: function investigation() {
      // 调用满意度调查
      ClinkAgent.investigation();
    },
    previewOutcall: function previewOutcall() {
      // 外呼
      ClinkAgent.previewOutcall(this.previewOutcallForm);
    },
    previewOutcallCancel: function previewOutcallCancel() {
      // 外呼取消
      ClinkAgent.previewOutcallCancel();
    },
    clinkAgentChangeBindTel: function clinkAgentChangeBindTel() {
      // 修改绑定电话
      var params = {
        bindTel: this.loginForm.bindTel,
        bindType: this.loginForm.bindType
      };
      ClinkAgent.changeBindTel(params);
    }
  }),
  watch: {
    WCState: function WCState(n) {
      if (n) {
        this.busyState('WC');
      } else {
        if (this.stopState !== 0) {
          this.idleState();
        }
      }
    }
  }
};
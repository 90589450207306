/**
 * 登录快过期时，用来刷新令牌
 *
 * */
import store from '@/store';
import { loginCur } from '@/api/apiLoginUrl';
import qs from 'querystring';
import { getReToken, removeToken } from '@/utils/auth';
export default function refreshToken(station) {
  return new Promise(function (resolve, reject) {
    var refreshData = {
      grant_type: 'refresh_token',
      scope: 'pc',
      client_id: 'UAC2020110502',
      client_secret: 'FLAre2YcRSjvf5jDqGbV6j6Q6BFmGg8E',
      refresh_token: getReToken()
    };

    if (station !== null && typeof station !== 'undefined') {
      refreshData['station'] = station;
    }

    loginCur(qs.stringify(refreshData)).then(function (reslogin) {
      // 清空原令牌
      removeToken();
      store.dispatch('login/setToken', reslogin);
      resolve(reslogin);
    }).catch(function (err) {
      reject(err);
    });
  });
}
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import * as uMengConstant from '@/constant/uMengConstant';
/**
 *友盟自定义事件注册
 * */
// h获取业务数据

var getBusinessData4Bind = function getBusinessData4Bind(binding) {
  var bindingValue = binding.value();
  var openPageTime = bindingValue.openPageTime;
  var beginTime = new Date(openPageTime).getTime();
  var endTime = new Date().getTime();
  var usedTime = Math.floor((endTime - beginTime) / 1000); // 两个时间戳相差的毫秒数

  bindingValue['usedTime'] = usedTime;
  return bindingValue;
};

export default {
  install: function install(Vue) {
    // 注册登录事件
    Vue.directive('umLogin', {
      bind: function bind(el, binding) {
        el.addEventListener('click', function () {
          var bindingValue = binding.value();

          if (window.aplus_queue) {
            window.aplus_queue.push({
              action: uMengConstant.UM_SEND_RECORD,
              arguments: [uMengConstant.UM_RECORD_LOGIN, 'CLK', bindingValue]
            });
          }
        }, false);
      },
      unbind: function unbind(el) {
        el.removeEventListener('click', function () {});
      }
    }); // 注册订单业务

    Vue.directive('umOrderBusiness', {
      bind: function bind(el, binding) {
        el.addEventListener('click', function () {
          if (window.aplus_queue) {
            window.aplus_queue.push({
              action: uMengConstant.UM_SEND_RECORD,
              arguments: [uMengConstant.UM_RECORD_ORDER_BUSINESS, 'CLK', getBusinessData4Bind(binding)]
            });
          }
        }, false);
      },
      unbind: function unbind(el) {
        el.removeEventListener('click', function () {});
      }
    }); // 注册工单业务

    Vue.directive('umWorkOrderBusiness', {
      bind: function bind(el, binding) {
        el.addEventListener('click', function () {
          if (window.aplus_queue) {
            window.aplus_queue.push({
              action: uMengConstant.UM_SEND_RECORD,
              arguments: [uMengConstant.UM_RECORD_WORK_ORDER_BUSINESS, 'CLK', getBusinessData4Bind(binding)]
            });
          }
        }, false);
      },
      unbind: function unbind(el) {
        el.removeEventListener('click', function () {});
      }
    }); // 页面按钮点击

    Vue.directive('umPageButtonClick', {
      bind: function bind(el, binding) {
        el.addEventListener('click', function () {
          var bindingValue = binding.value;
          var buttonName = {
            buttonName: bindingValue[0]
          };

          var buttonData = _objectSpread({}, buttonName, {}, bindingValue[1]);

          if (window.aplus_queue) {
            window.aplus_queue.push({
              action: uMengConstant.UM_SEND_RECORD,
              arguments: [uMengConstant.UM_RECORD_PAGE_BUTTON_CLICK, 'CLK', buttonData]
            });
          }
        }, false);
      },
      unbind: function unbind(el) {
        el.removeEventListener('click', function () {});
      }
    });
  }
};
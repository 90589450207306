import "core-js/modules/es6.array.find";
var state = {
  keepAlive: []
};
var mutations = {
  PUSH_KEEP: function PUSH_KEEP(state, pageName) {
    if (!state.keepAlive.find(function (ele) {
      return ele === pageName;
    })) {
      state.keepAlive.push(pageName);
    }
  },
  INIT_KEEP: function INIT_KEEP(state) {
    state.keepAlive = [];
  },
  DEL_KEEP: function DEL_KEEP(state, pageName) {
    state.keepAlive = state.keepAlive.filter(function (ele) {
      return ele !== pageName;
    });
  },
  REST_KEEP: function REST_KEEP(state) {
    state.keepAlive = [];
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
import "core-js/modules/es6.regexp.replace";
import _typeof from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/core-js/modules/es6.promise.js";
import "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/core-js/modules/es6.object.assign.js";
import "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import vueQr from 'vue-qr';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import { projectTheme } from '@/utils/projectTheme.js';
projectTheme();
import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon

import '@/permission'; // permission control

import CallCenterTitle from '@/components/CallCenterTitle';
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer';
import 'vue-directive-image-previewer/dist/assets/style.css';
import moment from 'vue-moment';
import VueAMap from 'vue-amap';
import "./directive/index.js";
import { phone } from '@/utils/phoneCmd.js';
import VueClipboard from 'vue-clipboard2';
import Schema from 'async-validator'; // 设置报错提示信息

Schema.messages.required = function (fieldName) {
  return '该项为必填项';
};

import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import UMMonitor from '@/directive/uMengMonitor';
import AvueSelectTemplate from '@/components/business/AvueSelectTemplate/index';
import Storage from 'vue-ls';
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
var options = {
  namespace: 'vueLocal__',
  // 存储的key键前缀，可自定义
  name: 'ls',
  // 命名Vue变量.ls则使用为this.$ls或者Vue.ls,
  storage: 'local' // 存储名称: session, local, memory，更改为session则默认存储在sessionStorage

};
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);
Vue.use(Storage, options); // 使用vue-ls插件

Vue.use(UMMonitor);
Vue.use(VXETable);
Vue.use(VueClipboard);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '702366216c3cbb92e6cb41441cec5141',
  // 插件集合
  plugin: ['AMapManager', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'Geocoder', 'Geolocation', 'AMap.MarkerClusterer', 'AMap.CircleEditor', 'AMap.MouseTool', 'AMap.Driving', 'AMap.CitySearch', 'AMap.InfoWindow', 'AMap.LngLat', 'AMap.DistrictSearch', 'AMap.TileLayer.Traffic', 'AMap.Heatmap', 'AMap.Autocomplete', 'AMap.PlaceSearch'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0.11' // 版本号

});
Vue.use(VueDirectiveImagePreviewer); // 日期处理

Vue.use(moment); // 二维码处理

Vue.use(vueQr); // set ElementUI lang to EN

Vue.use(ElementUI, {
  locale: locale
});
Vue.use(Avue);
Vue.component('avueSelectTemplate', AvueSelectTemplate);
Vue.component('CallCenterTitle', CallCenterTitle);
Vue.prototype.$bus = new Vue();

Vue.prototype.$message = function (params) {
  var tp = _typeof(params);

  if (tp === 'string' || tp === 'number') {
    Message({
      message: params,
      type: 'info',
      duration: 1500,
      offset: 60
    });
  } else if (tp === 'object') {
    Message({
      message: params.message,
      type: params.type,
      duration: params.duration || 1500,
      offset: 60,
      showClose: params.showClose || false
    });
  } else {
    throw new Error('参数错误');
  }
};

Vue.config.productionTip = false;

Vue.prototype.$safariTime = function (time) {
  // 苹果safari IE无法解析YYYY-MM-DD hh:mm:ss, 需要转换成YYYY/MM/DD hh:mm:ss
  return time.replace(/-/ig, '/');
};

Vue.directive('phone', phone);
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
/**
 * 接口URL文档
 * 刘松
 * ceshi
 */
import request from "./request"; // 引入axios的封装方法
// 登录接口

export var loginCur = function loginCur(params) {
  return request('post', "/loginApi/oauth/token?" + params);
}; // 获取登录验证短信接口

export var getLoginsms = function getLoginsms(params) {
  return request('post', "/loginApi/v1/uac/sms?" + params);
}; // 获取登录二维码内容

export var getLoginQrCode = function getLoginQrCode() {
  return request('get', "/loginApi/v1/uac/loginQrCode");
}; // 轮询登录二维码

export var loopLoginQrCode = function loopLoginQrCode(params) {
  // return Promise.resolve;
  return request('get', "/loginApi/v1/uac/loopQrCode", params);
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !["ckSubcontracting", "ckSubcontracting_local"].includes(
                _vm.nodeEnv
              ),
              expression:
                "!['ckSubcontracting','ckSubcontracting_local'].includes(nodeEnv)"
            }
          ]
        },
        [_c("Toolbar", { ref: "ToolBarRef" })],
        1
      ),
      _vm._v(" "),
      _vm.isShowCount
        ? _c(
            "div",
            { staticClass: "alert-msg", on: { click: _vm.hAlertMsg } },
            [
              _c(
                "el-badge",
                { staticClass: "icon-item", attrs: { value: _vm.count } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "商机预约提醒",
                        placement: "bottom",
                        effect: "light"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-chat-dot-square" })]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "el-dropdown-link" }, [
                _vm._v("\n          " + _vm._s(_vm.orgName) + "\n          "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.orgChange }
                      },
                      [_vm._v("组织切换")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.gotoHelpDoc }
                      },
                      [_vm._v("帮助中心")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "orgChangeDialog",
          attrs: {
            modal: false,
            visible: _vm.dialogDisabled,
            title: "组织切换",
            width: "65%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDisabled = $event
            }
          }
        },
        [
          _c("orgChangeList", {
            ref: "orgChangeList",
            attrs: { dialog: _vm.dialogDisabled }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" }
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        mouseenter: _vm.showVersion,
                        mouseleave: _vm.showVersion
                      }
                    },
                    [_c("div", { staticClass: "sidebar-logo appHome-logo" })]
                  )
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" }
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        mouseenter: _vm.showVersion,
                        mouseleave: _vm.showVersion
                      }
                    },
                    [
                      _c("div", { staticClass: "sidebar-logo appHome-logo" }),
                      _vm._v(" "),
                      _c("h1", { staticClass: "sidebar-title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ])
                    ]
                  )
                ]
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.versionBool,
              expression: "versionBool"
            }
          ],
          staticClass: "version"
        },
        [_vm._v(_vm._s(_vm.version))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
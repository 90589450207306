import { mobileHidden } from '@/utils/sensitiveHidden';
/**
 * 作者：陈娜
 * 作用：针对有分页、表格的界面，根据网络请求情况，重置分页、表格数据
 * @param {Number} total 总页数
 * @param {Number} curPage 当前页码
 * @param {Number} limit 每页数据条数
 * @param {Number} tableData 表格数据
 *  @param {object} that vue实例化对象this指向
 */

export function resetPage(total, curPage, limit, tableData, that) {
  var tempTableData = tableData.map(function (ele) {
    if (ele.telNo) {
      ele['mobileHiddenTelNo'] = mobileHidden(ele.telNo);
    }

    return ele;
  }); // 分页相关数据

  that.total = total;
  that.curPage = curPage;
  that.limit = limit; // 表格数据

  that.tableData = tempTableData;
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("avue-select", {
        ref: "SelectRef",
        attrs: {
          all: "",
          multiple: _vm.multiple,
          placeholder: _vm.placeholder,
          type: "tree",
          dic: _vm.dic
        },
        on: { focus: _vm.canChangeSelect, change: _vm.changeSelect },
        model: {
          value: _vm.formModel,
          callback: function($$v) {
            _vm.formModel = $$v
          },
          expression: "formModel"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.find";
import axios from 'axios'; // import { throwErr } from '@/api/throwErr'; // utils 捕捉服务端http状态码的方法

import { Message } from 'element-ui';
import store from '@/store';
import router from 'vue-router';
import { setTime, getTime } from '@/utils/auth.js';
import { loginCur } from '@/api/apiLoginUrl';
import qs from 'querystring';
import { getReToken, getLoginInfo } from '@/utils/auth'; // import { version, date } from '../../version';
// import refreshToken from '@/utils/refreshToken';
// const isRefreshing = false;

var whiteList = ['/oauth/token', 'uac/sms', 'loginQrCode', 'notice/query', 'uac/loopQrCode']; // 请求拦截器，处理queryParams为列表时的解析方式
// axios.interceptors.request.use(async (config) => {
//   // 只针对get方式进行序列化
//   if (config.method === 'get') {
//     config.paramsSerializer = function (params) {
//       return qs.stringify(params, { arrayFormat: 'repeat' });
//     };
//   }
//   return config;
// });
// 添加响应拦截器

axios.interceptors.response.use(function (response) {
  // 后台状态码
  var code = response.data.code;
  var retCode = response.data.retCode;
  var responseType = response.config.responseType;
  var content = response.headers['content-type'];

  var codefn = function codefn() {
    return +code === 200 || code === '201' || responseType === 'arraybuffer' || responseType === 'blob' || retCode === 0 || code === 0 || content === 'image/jpeg;charset=UTF-8' || content === 'application/octet-stream;charset=utf-8' || response.data.refresh_token || typeof response.data === 'number';
  }; // console.log(content);


  if (codefn()) {
    // 服务端定义的响应code码为0时请求成功
    var fd = whiteList.find(function (ele) {
      return !!~response.config.url.indexOf(ele);
    });
    var time = getTime();

    if (!fd) {
      if (time) {
        var min = 60 * 1000; // 当前时间-上次请求时间 小于 110 分钟  并且  当前时间-上次请求时间 大于 100分钟
        // 本次请求与上次拿token时间大于30分钟,  就给他换新的

        if (new Date() - +time > 30 * min) {
          setTime(+new Date());
          var refreshData = {
            grant_type: 'refresh_token',
            scope: 'pc',
            client_id: 'UAC2020110502',
            client_secret: 'FLAre2YcRSjvf5jDqGbV6j6Q6BFmGg8E',
            refresh_token: getReToken(),
            station: getLoginInfo().org.orgId
          };
          loginCur(qs.stringify(refreshData)).then(function (reslogin) {
            store.dispatch('login/setToken', reslogin);
          });
        }
      } else {
        setTime(+new Date());
      } // setTime(+new Date());

    }

    return Promise.resolve(response.data); // 使用Promise.resolve 正常响应
  } else if (+code === 501) {
    Message({
      message: '该任务已不存在（可能已完成或被他人接单）',
      type: 'error'
    });
  } else {
    if (response && response.config) {
      var _requestUrl = response.config.url;
      var _code = response.data.code;

      if (_code === 400 && typeof _requestUrl === 'string' && _requestUrl.indexOf('crm/web/radiusQuery/queryAccessLog') !== -1) {
        Message({
          message: '暂无数据'
        });
        return Promise.resolve(response.data);
      }
    }

    Message({
      message: JSON.stringify(response.data.msg || response.data.message)
    });
    return Promise.resolve(response.data);
  }
}, function (error) {
  if (error.response) {
    if (error.response.data.msg) {
      Message({
        message: JSON.stringify(error.response.data.msg),
        duration: 2000
      });
    } else if (error.response.request.responseType === 'blob') {
      var arr = error.response.config.url.split('/'); // 电销模块的活动列表导出提示信息需要单独处理

      if (arr[3] === 'activity-instance') {
        Message({
          message: '数据量大于5万，无法导出',
          duration: 2000
        });
      } else {
        Message({
          message: '内容加载失败',
          duration: 2000
        });
      }
    } else if (error.response.status === 401) {
      Message({
        message: '未登录',
        type: 'error'
      });
      store.dispatch('setUserInfo', {});
      store.dispatch('login/logout').then(function (msg) {
        router.push("/login");
      });
    } else if (error.response.status === 501) {
      Message({
        message: '该任务已不存在（可能已完成或被他人接单）',
        type: 'error'
      });
    } else if (error.response.status === 400) {
      return Promise.reject(error);
    } else if (error.response.status === 500) {
      Message({
        message: '服务器异常',
        type: 'error'
      });
    } else if (error.response.status === 511) {
      Message({
        message: error.response.data.message,
        type: 'error'
      });
    }
  }

  return Promise.reject(error);
});
export default function request(method, url, data) {
  var timeout = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 20 * 1000;
  var body = arguments.length > 4 ? arguments[4] : undefined;
  // 暴露 request 给我们好API 管理
  var headers = {};

  if (localStorage.getItem('token') && !whiteList.find(function (ele) {
    return !!~url.indexOf(ele);
  })) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('token');

    if (headers && typeof headers.customReferer === 'undefined') {
      // 赋值customReferer
      var customReferer = window.location.href;

      if (typeof customReferer === 'string') {
        headers['customReferer'] = customReferer.length < 100 ? customReferer : customReferer.substring(0, 99); // headers['customVersion'] = `pc:${date} - ${version}`;
      }
    }
  }

  method = method.toLocaleLowerCase(); // 封装RESTful API的各种请求方式 以 post get delete为例

  switch (method) {
    case 'post':
      return axios({
        url: url,
        method: 'post',
        data: data,
        headers: headers
      });

    case 'get':
      return axios({
        url: url,
        method: 'get',
        params: data,
        headers: headers
      });

    case 'getbody':
      return axios({
        url: url,
        method: 'get',
        params: data,
        data: body,
        headers: headers
      });

    case 'delete':
      return axios({
        url: url,
        method: 'delete',
        params: data,
        headers: headers,
        data: body
      });

    case 'put':
      return axios({
        url: url,
        method: 'put',
        data: data,
        headers: headers
      });

    case 'file':
      headers['Content-Type'] = 'multipart/form-data';
      return axios({
        url: url,
        method: 'post',
        timeout: timeout,
        data: data,
        headers: headers
      });

    case 'img':
      return axios({
        url: url,
        method: 'get',
        headers: headers,
        responseType: 'blob',
        params: data,
        timeout: timeout
      });

    case 'blobfile':
      return axios({
        url: url,
        method: 'post',
        headers: headers,
        responseType: 'blob',
        data: data,
        timeout: timeout
      });
  }
}
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { constantRoutes } from '@/router';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: [],
  currentPath: ''
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_CURRENT_PATH: function SET_CURRENT_PATH(state, path) {
    state.currentPath = path;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
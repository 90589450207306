var state = {
  dialogDisabled: false
};
var mutations = {
  CHANGE_DISABLED: function CHANGE_DISABLED(state, bool) {
    state.dialogDisabled = bool;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};
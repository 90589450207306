var state = {
  orderData: {
    totalCount: 0,
    list: []
  },
  orderState: null,
  buildId: ''
};
var mutations = {
  SET_ORDERINQUIRY: function SET_ORDERINQUIRY(state, orderData) {
    state.orderData = orderData;
  },
  SET_ORDERSTATE: function SET_ORDERSTATE(state, orderState) {
    state.orderState = orderState;
  },
  SET_BUILDID: function SET_BUILDID(state, buildId) {
    state.buildId = buildId;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import _objectSpread from "/home/chdeploy/jenkins/jenkins/workspace/ch_deploy_webpc_test-jh/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { queryOperatorOrOrgList } from '@/api/apiUrl.js';
import LoginService from '@/service/loginService/loginService';
import { resetPage } from '@/utils/retSetAjaxData';
import { getLoginInfo } from '@/utils/auth.js';
import refreshToken from '@/utils/refreshToken';
export default {
  name: 'ResTypeQuery',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      tableData: [],
      orgForm: {
        orgName: ''
      },
      operatorCode: getLoginInfo().operatorCode,
      currentOrgId: getLoginInfo().org.orgId,
      total: 0,
      // 总数据条数
      currentPage: 0,
      // 当前页码，目前按照0为第一页
      limit: 10 // 每页数据条数

    };
  },
  mounted: function mounted() {
    this.searchBtn(0);
  },
  methods: {
    stateformatter: function stateformatter(row) {
      if (row.isDefault === 1) {
        return '是';
      } else {
        return '否';
      }
    },
    searchBtn: function searchBtn(page) {
      var _this = this;

      var data = {
        orgName: this.orgForm.orgName,
        operatorId: getLoginInfo().operatorId,
        curPage: page,
        limit: 10
      };
      this.tableData = [];
      queryOperatorOrOrgList(data).then(function (res) {
        // this.tableData = res.content.list;
        resetPage(res.content.total, 0, 10, res.content.records, _this);
        _this.curPage = res.content.current;
      });
    },
    // 组织切换
    startResType: function startResType(index, operatorId, orgId, orgName) {
      var _this2 = this;

      this.$confirm('点击"确定"，将切换到' + orgName, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        refreshToken(orgId).then(function (reslogin) {
          new LoginService().queryUserInfoByMobile(_this2.operatorCode, {
            orgId: orgId
          }).then(function (resrole) {
            _this2.$store.dispatch('login/setToken', reslogin);

            _this2.$store.dispatch('login/login', _objectSpread({}, resrole.content, {}, reslogin)).then(function () {
              var bsi = _this2.$store.state.tagsView.visitedViews.find(function (ele) {
                return ele.name === 'Bossinformation';
              });

              _this2.$store.dispatch('tagsView/delAllViews').then(function (_ref) {
                var visitedViews = _ref.visitedViews;

                if (bsi) {
                  _this2.$router.push(bsi.fullPath);
                } else {
                  _this2.$router.push('/');
                } // const { name } = this.$route;
                // console.log('???==>', name);
                // if (name) {
                //   this.$store.dispatch('tagsView/addView', this.$route);
                // }

              });
            });

            _this2.$parent.$parent.dialogDisabled = false;

            // 发送事件修改登录信息
            _this2.$bus.$emit('changeLoginInfo', reslogin);
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '操作已取消!'
        });
      });
    },
    // 每页多少条数据
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.searchBtn(0);
    },
    // 当前页码
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.searchBtn(val);
    }
  },
  watch: {
    dialog: function dialog(n, o) {
      if (n) {
        this.operatorCode = getLoginInfo().operatorCode;
        this.currentOrgId = getLoginInfo().org.orgId;
      }
    }
  }
};
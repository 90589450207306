//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CallCenterTitle',
  props: {
    title: {
      type: String,
      default: '个宽新开工单'
    }
  }
};